import React from 'react';
import { Pagination } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';

const customizeItem = (page, type/* , originalElement */) => {
  if(type === 'prev') {
    return <LeftOutlined type="left" style={{ fontWeight: 'bold', fontSize: 18, color: '#7B9897' }} />;
  }
  if(type === 'next') {
    return <RightOutlined type="right" style={{ fontWeight: 'bold', fontSize: 18, color: '#7B9897' }} />;
  }
  if(type === 'page') {
    return <div style={{ fontWeight: 'bold', fontSize: 18, color: '#7B9897' }}>{`${page}`}</div>;
  }
  return null;
};

class OrdersPagination extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <div className="order-pagination">
        {
          props.total
            ? (
              <Pagination
                {...props}
                itemRender={customizeItem}
              />
            ) : null
        }
      </div>
    );
  }
}

OrdersPagination.propType = {
  limit: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
};

OrdersPagination.defaultProps = {
  limit: 10,
  total: 0,
  page: 1,
};
export default OrdersPagination;
