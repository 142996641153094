import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table, Input, Button, Form } from 'antd';
import moment from 'moment';
import MobileBackendTableUsers from './MobileBackendTableUsers';


class BackendTableUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      entityName: '',
      email: '',
      isMobile: window.innerWidth < 768,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.changeTableType);
    const { pagination } = this.props.data;
    this.props.getPendingUsers(pagination);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeTableType);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.tabId !== this.props.tabId) {
      this.onPageChange(0);
    }
  }

  changeTableType = () => {
    const { isMobile } = this.state;
    if(window.innerWidth < 768 && !isMobile) {
      this.setState({ isMobile: true });
    } else if(window.innerWidth >= 768 && isMobile) {
      this.setState({ isMobile: false });
    }
  };

  handleUser = (user) => {
    this.props.history.push(`/backend/users/${user.id}`);
  };

  onPageChange = (page) => {
    const { limit } = this.props.data.pagination;
    const { firstName, lastName, entityName, email } = this.state;
    this.props.getPendingUsers({
      page,
      limit,
      firstName,
      lastName,
      entityName,
      email,
    });
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    // e.preventDefault();
    this.onPageChange(0);
  };

  handleApprove = async (userId) => {
    this.props.approvePendingUser({ userId });
    this.onPageChange(0);
  };

  handleBlacklist = async (userId) => {
    this.props.blacklistUser({ userId });
    this.onPageChange(0);
  };

  render() {
    const { users } = this.props.data;
    const filteredFirstName = users ? [...new Set(users.map(e => e.firstName))] : [];
    const filteredLastName = users ? [...new Set(users.map(e => e.lastName))] : [];
    const filteredEmail = users ? [...new Set(users.map(e => e.email))] : [];
    const filteredEntityName = users ? [...new Set(users.map(e => e.entityName))] : [];
    const { isMobile } = this.state;
    const columns = [
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>First Name</span>,
        dataIndex: 'firstName',
        filters: filteredFirstName.map(item => ({
          text: item,
          value: item,
        }
        )),
        onFilter: (value, record) => record.firstName.indexOf(value) === 0,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        sortDirections: ['ascend', 'descend'],
        render: text => <span style={{ color: 'black' }}>{text}</span>,
        className: 'table-user-content',
        width: '18%',
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Last Name</span>,
        dataIndex: 'lastName',
        filters: filteredLastName.map(item => ({
          text: item,
          value: item,
        })),
        onFilter: (value, record) => record.lastName.indexOf(value) === 0,
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        sortDirections: ['ascend', 'descend'],
        render: text => <span style={{ color: 'black' }}>{text}</span>,
        className: 'table-user-content',
        width: '18%',
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Email</span>,
        dataIndex: 'email',
        filters: filteredEmail.map(item => ({
          text: item,
          value: item,
        }
        )),
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['ascend', 'descend'],
        render: text => <span style={{ color: 'black' }}>{text}</span>,
        className: 'table-user-content',
        width: '18%',
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Entity</span>,
        dataIndex: 'entityName',
        filters: filteredEntityName.map(item => ({
          text: item,
          value: item,
        })),
        onFilter: (value, record) => record.entityName.indexOf(value) === 0,
        sorter: (a, b) => a.entityName.localeCompare(b.entityName),
        sortDirections: ['ascend', 'descend'],
        render: text => <span style={{ color: 'black' }}>{text}</span>,
        className: 'table-user-content',
        width: '18%',
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Registration Date</span>,
        dataIndex: 'createdAt',
        sorter: (a, b) => moment(a.createdAt).diff(b.createdAt),
        sortDirections: ['ascend', 'descend'],
        render: text => <span style={{ color: 'black' }}>{text}</span>,
        className: 'table-user-content',
        width: '18%',
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Action</span>,
        dataIndex: 'id',
        render: text => (
          <div>
            <Button type="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleApprove(text) }}>Approve</Button>
            <Button type="danger" ghost onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.handleBlacklist(text) }}>Blacklist</Button>
          </div>
        ),
        className: 'table-user-content',
        width: '10%',
      },
    ];

    return (
      <React.Fragment>
        <div className="search-form">
          <Form layout="inline" onFinish={this.handleSubmit}>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>First Name:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="firstName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Last Name:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="lastName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Entity:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="entityName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Email:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="email"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="btn-turncoin"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        {isMobile
          ? (<MobileBackendTableUsers users={users} handleUser={this.handleUser} />)
          : (
            <Table
              columns={columns}
              dataSource={users}
              bordered
              rowKey="id"
              pagination={false}
              loading={this.props.status === 'running'}
              rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
              onRow={(record) => ({
                onClick: () => this.handleUser(record),
              })}
            />
          )
        }
      </React.Fragment>
    );
  }
}

BackendTableUsers.propTypes = {
  data: PropTypes.shape({
    users: PropTypes.array,
    pagination: PropTypes.object,
  }),
  status: PropTypes.string,
  getPendingUsers: PropTypes.func.isRequired,
  approvePendingUser: PropTypes.func.isRequired,
  blacklistUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

BackendTableUsers.defaultProps = {
  status: 'idle',
  data: {
    users: [],
    pagination: {},
  },
};

export default withRouter(BackendTableUsers);
