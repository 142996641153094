import React from 'react';
import { connect } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authActions } from '../../core';
import { ApiService } from '../../services/restService';

class ResetPassword extends React.Component {
  state = {
    email: '',
    password: '',
    password2: '',
    error: '',
    isConfirmed: false,
    token: ',',
    status: 'idle'
  };

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get('email') || this.props.auth.user.email;
    const token = params.get('token');
    this.setState({email, token});
  }

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  onSuccess = () => {
    this.props.history.push('/');
  };

  onFail = (response) => {
    this.setState({ error: '', status: 'idle' });
    this.setState({ error: response.message });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { password, password2 } = this.state;
    if(password !== password2) {
      this.setState({ error: 'The passwords entered do not match.' });
      return;
    }
    this.resetPassword();
  };

  resetPassword = () => {
    const { email, password, token } = this.state;
    this.setState({ status: 'running' });

    ApiService({
      api: '/api/auth/reset-password',
      method: 'POST',
      params: {
        email,
        resetPasswordToken: token,
        newPassword: password,
      },
    })
      .then((data) => {
        if(data.success) {
          this.props.history.push('/login');
        } else {
          console.log('data error', data);
          this.setState({ error: data.message, status: 'idle' });
        }
      });
  };

  render() {
    const { error, status } = this.state;
    return (
      <div className="reset_password_body">
        <div className="xchange_copywrite_message">© TheXchange™ 2017-2021. All rights reserved.</div>
        <div className="register_body_wrapper">
          <img src="/images/thexchange-logo.svg" loading="lazy" alt="" className="register_page_logo" />
          <h2 className="login_form_title">RESET PASSWORD</h2>
          <div className="login_form_block w-form">
            <form id="wf-form-Reset-Password" name="wf-form-Reset-Password" data-name="Reset Password" onSubmit={this.handleSubmit}>
              <input type="password" onChange={this.onFieldChange} className="turncoin_registration_input_field w-input" 
              maxLength="256" name="password" data-name="New Password" placeholder="New Password" id="New-Password" required={true} />
              <input type="password" onChange={this.onFieldChange} className="turncoin_registration_input_field w-input" 
              maxLength="256" name="password2" data-name="Confirm Password" placeholder="Confirm Password" id="Confirm-Password" required={true} />
              {/*<div className="login_form_btn_wrapper">
                <div className="turncoin_register-page_btn">
                  <input type="submit" value="Submit" data-wait="Please wait..." className="turncoin_submit_action w-button" />
                  <div>RESET PASSWORD</div>
                </div>
    </div>*/}
    <div className={`register_form_btn_wrapper" ${status === 'running' && 'disable-btn'}`} style={{paddingTop:'10px'}}>
                    <div className='turncoin_register-page_btn'>
                    <input
                      type="submit"
                      value="Reset Password"
                      style={{backgroundColor: 'transparent', border: 0, width:'100%', height:'40px'}}
                      data-wait="Please wait..."
                      // className={`turncoin_submit_action w-button ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}
                      disabled={status === 'running' ? true : false}
                      />
                    </div>
                  </div>
            </form>
            {!(error === '' || error === undefined) && (
                <div className="error_message">
                  {error}
                </div>
              )
            }
          </div>
            <Link to="/login" className="turncoin_back_button w-inline-block" onClick={() => this.setState({ isConfirmed: false, email: '', password: '', password2: '' })}>
              <div className="text-block-6" style={{color:'wheat', paddingTop:'20px'}}>Back</div>
            </Link>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  ResetPassword: PropTypes.func.isRequired,
};


function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    auth,
    isAuthenticated,
    status: auth.status,
  };
}

const mapDispatchToProps = (dispatch) => ({
  ResetPassword: (payload, resolve, reject) => dispatch(authActions.createPassword(payload, resolve, reject)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
