import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { authActions } from '../../core';
import {
  ErrorPage, PageNotFound, Login, Logout, RegisterSuccess, CheckEmail,CreatePassword, ResetPassword, Forgot, Dashboard, Backend,
} from '..';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

class DefaultLayout extends React.PureComponent {
  componentDidMount() {
    this.props.getUserInfo();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <ScrollToTop>
            <Switch>
              {/*<Redirect exact from="/" to="/login" />*/}
              <Route exact path="/login/forgot" component={Forgot} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={CheckEmail} />
              <Route exact path="/register-success" component={RegisterSuccess} />
              <Route exact path="/create-password" component={CreatePassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <PrivateRoute exact path="/logout" component={Logout} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/backend" component={Backend} />
              <Route path="/error" component={ErrorPage} />
              <Route component={PageNotFound} />

            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}
DefaultLayout.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getUserInfo: authActions.getUserInfoRequest,
};

export default connect(null, mapDispatchToProps)(DefaultLayout);
