import {
  put,
  call,
  fork,
  all,
  take,
  takeEvery,
} from 'redux-saga/effects';
import moment from 'moment';
import { message } from 'antd';
import { authActions } from '../auth/actions';
import {
  adminActions,
  GET_USERS_REQUEST,
  GET_PENDING_USERS_REQUEST,
  APPROVE_PENDING_USER_REQUEST,
  GET_BLACKLIST_USERS_REQUEST,
  BLACKLIST_USER_REQUEST,
  UNBLACKLIST_USER_REQUEST,
  GET_USER_LOGINS_REQUEST,
  GENERATE_CODE_REQUEST,
  GET_REGISTRATION_CODE_REQUEST,
  GET_ALL_ORDERS_REQUEST,
  GET_USER_ORDERS_REQUEST,
  UPDATE_USER_ORDER_STATUS_REQUEST,
  DELETE_ORDER_REQUEST,
  UPDATE_ORDER_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
  GET_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  GET_NEWS_REQUEST,
  UPLOAD_PHOTO_REQUEST,
  CREATE_NEWS_REQUEST,
  DELETE_NEWS_REQUEST,
  GET_NEWS_BY_ID_REQUEST,
  UPDATE_NEWS_REQUEST,
  UPDATE_USER_INFO_REQUEST,
  GET_USER_BY_ID_REQUEST,
  CREATE_ORDER_REQUEST,
  GET_ORDERS_BY_USER_ID_REQUEST,
  GET_ORDERS_SUMMARY_REQUEST,
  GET_INVESTMENT_ROUND_REQUEST,
} from './actions';

import { ApiService } from '../../../services';
import { apiEndpoint } from '../../../services/constants';
import { calculateQueryStr } from '../../../utilities';

function deserialize(data) {
  const { date } = data;
  const d = moment(date);
  return {
    ...data,
    date: d,
  };
}

function serialize(data) {
  const { date } = data;
  const d = date.utc();
  return {
    ...data,
    date: d,
  };
}

export function* asyncGetUsersRequest({ payload = {} }) {
  const queryStr = calculateQueryStr(payload);
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users?${queryStr}`,
      method: 'GET',
    });
    if(!response.success) {
      yield put(authActions.loginFailure({ message: response.message }));
      throw new Error(response.message);
    }
    yield put(adminActions.usersSuccess({ users: response.data }));
  } catch(err) {
    yield put(adminActions.usersFailure(err));
  }
}

export function* asyncGetPendingUsersRequest({ payload = {} }) {
  const queryStr = calculateQueryStr(payload);
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/unapprovedUsers?${queryStr}`,
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getPendingUsersSuccess({ pendingUsers: response.data }));
  } catch(err) {
    yield put(adminActions.getPendingUsersFailure(err));
  }
}

export function* asyncApprovePendingUserRequest({ payload = {} }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/users/approveUser',
      method: 'POST',
      params: payload,
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.approvePendingUserSuccess({ approvedUserId: payload.userId }));
  } catch(err) {
    yield put(adminActions.approvePendingUserSuccess(err));
  }
}

export function* asyncGetBlacklistUsersRequest({ payload = {} }) {
  const queryStr = calculateQueryStr(payload);
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/blacklistedUsers?${queryStr}`,
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getBlacklistUsersSuccess({ blacklistUsers: response.data }));
  } catch(err) {
    yield put(adminActions.getBlacklistUsersFailure(err));
  }
}

export function* asyncBlacklistUserRequest({ payload = {} }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/users/blacklistUser',
      method: 'POST',
      params: payload,
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.blacklistUserSuccess({ blacklistedUserId: payload.userId }));
  } catch(err) {
    yield put(adminActions.blacklistUserFailure(err));
  }
}

export function* asyncUnblacklistUserRequest({ payload = {} }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/users/removeBlacklistUser',
      method: 'POST',
      params: payload,
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.unblacklistUserSuccess({ blacklistedUserId: payload.userId }));
  } catch(err) {
    yield put(adminActions.unblacklistUserFailure(err));
  }
}

export function* asyncGetUserLoginsRequest({ payload = {} }) {
  const queryStr = calculateQueryStr(payload);
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/loggedInUsers?${queryStr}`,
      method: 'GET',
    });

    if(!response.success) {
      yield put(authActions.loginFailure({ message: response.message }));
      throw new Error(response.message);
    }
    yield put(adminActions.userLoginsSuccess({ users: response.data }));
  } catch(err) {
    yield put(adminActions.userLoginsFailure(err));
  }
}

export function* asyncGenerateCodeRequest({ payload = {} }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/registrationCode',
      method: 'POST',
      params: payload,
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.generateCodeSuccess({ registrationCode: response.data }));
  } catch(err) {
    yield put(adminActions.generateCodeFailure(err));
  }
}

export function* asyncGetRegistrationCodeRequest() {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/registrationCode',
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getRegistrationCodeSuccess({ registrationCode: response.data }));
  } catch(err) {
    yield put(adminActions.getRegistrationCodeFailure(err));
  }
}

export function* asyncGetOrdersRequest({ payload }) {
  const queryStr = calculateQueryStr(payload);

  try {
    const response = yield call(ApiService, {
      api: `/api/admin/orders?${queryStr}`,
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.ordersSuccess({ params: payload, data: response.data }));
  } catch(err) {
    yield put(adminActions.ordersFailure({ params: payload }));
  }
}

export function* asyncGetUserOrdersRequest({ payload }) {
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/${payload}`,
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getUserOrdersSuccess({ ordersUserList: response.data.orders }));
  } catch(err) {
    yield put(adminActions.getUserOrdersFailure(err));
  }
}

export function* asyncUpdateUserOrderRequest({ payload }) {
  const { status, id } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/orders/status/${id}`,
      method: 'POST',
      params: {
        status,
      },
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.updateUserOrdersSuccess(response.success));
  } catch(err) {
    yield put(adminActions.updateUserOrdersFailure(err));
  }
}

export function* asyncDeleteOrderRequest({ payload, resolve, reject }) {
  const { id } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/orders/${id}`,
      method: 'DELETE',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.deleteOrderSuccess(response.success));
    resolve(response);
  } catch(err) {
    yield put(adminActions.deleteOrderFailure(err));
    reject(err);
  }
}

export function* asyncUpdateOrderRequest({ payload, resolve, reject }) {
  const { orderId } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/orders/${orderId}`,
      method: 'PUT',
      params: payload,
    });

    if(!response.success) {
      if(response.errors) {
        yield put(adminActions.updateOrderFailure());
        reject(response.errors);
      } else {
        throw new Error(response.message);
      }
    } else {
      yield put(adminActions.updateOrderSuccess(response.data));
      resolve(response.data);
    }
  } catch(err) {
    yield put(adminActions.updateOrderFailure());
    message.error(err.message);
  }
}

export function* asyncUploadDocumentRequest({ payload, resolve, reject }) {
  const { formData } = payload;

  try {
    let response = yield call(fetch, `${apiEndpoint}/api/admin/documents/`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    response = yield response.json();
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.uploadDocumentSuccess({ newDocument: response.data }));
    resolve(response);
  } catch(err) {
    yield put(adminActions.uploadDocumentFailure(err));
    reject(err);
  }
}

export function* asyncGetDocumentRequest() {
  try {
    const response = yield call(ApiService, {
      api: '/api/documents/',
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getDocumentSuccess({ documents: response.data }));
    // resolve(response);
  } catch(err) {
    yield put(adminActions.getDocumentFailure(err));
    // reject(err);
  }
}

export function* asyncDeleteDocumentRequest({ payload, resolve, reject }) {
  const { documentId } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/documents/${documentId}`,
      method: 'DELETE',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.deleteDocumentSuccess({ documentId }));
    resolve(response);
  } catch(err) {
    yield put(adminActions.deleteDocumentFailure(err));
    reject(err);
  }
}

export function* asyncGetNewsRequest() {
  try {
    const response = yield call(ApiService, {
      api: '/api/news',
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getNewsSuccess({ news: response.data }));
  } catch(err) {
    yield put(adminActions.getNewsFailure(err));
  }
}

export function* asyncUploadPhotoRequest({ payload }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/photos',
      method: 'POST',
      params: payload,
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.uploadPhotoSuccess({ photo: response.data }));
  } catch(err) {
    yield put(adminActions.uploadPhotoFailure(err));
  }
}

export function* asyncCreateNewsRequest({ payload, resolve }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/news',
      method: 'POST',
      params: serialize(payload),
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.createNewsSuccess());
    resolve();
  } catch(err) {
    yield put(adminActions.createNewsFailure(err));
  }
}

export function* asyncDeleteNewsRequest({ payload, resolve, reject }) {
  const { newsId } = payload;
  try {
    const response = yield call(fetch, `${apiEndpoint}/api/admin/news/${newsId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if(response.status !== 204) {
      throw new Error(response.status);
    }
    yield put(adminActions.deleteNewsSuccess({ newsId }));
    resolve();
  } catch(err) {
    yield put(adminActions.deleteNewsFailure(err));
    reject();
  }
}

export function* asyncGetNewsByIdRequest({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/news/${id}`,
      method: 'GET',
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    const { data } = response;
    yield put(adminActions.getNewsByIdSuccess(
      {
        news: deserialize(data),
      },
    ));
  } catch(err) {
    yield put(adminActions.getNewsByIdFailure(err));
  }
}

export function* asyncUpdateNews({ payload, resolve }) {
  const { id } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/news/${id}`,
      method: 'PUT',
      params: serialize(payload),
    });
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.updateNewsSuccess({ news: deserialize(response.data) }));
    resolve();
  } catch(err) {
    yield put(adminActions.updateNewsFailure(err));
  }
}

export function* asyncUpdateUserInfoRequest({ payload, resolve, reject }) {
  const { userId } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/${userId}/`,
      method: 'PUT',
      params: payload,
    });

    if(!response.success) {
      if(response.errors) {
        yield put(adminActions.updateUserInfoFailure());
        reject(response.errors);
      } else {
        throw new Error(response.message);
      }
    } else {
      yield put(adminActions.updateUserInfoSuccess(response.data));
      resolve(response.message);
    }
  } catch(err) {
    yield put(adminActions.updateUserInfoFailure({}));
    message.error(err.message);
  }
}

export function* asyncGetUserByIdRequest({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/${userId}`,
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getUserByIdSuccess(response.data));
  } catch(err) {
    yield put(adminActions.getUserByIdFailure(err));
  }
}

export function* asyncGetOrdersByUserId({ payload }) {
  const { userId } = payload;
  try {
    const response = yield call(ApiService, {
      api: `/api/admin/users/${userId}/orders`,
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getOrdersByUserIdSuccess(response.data));
  } catch(err) {
    yield put(adminActions.getOrdersByUserIdFailure(err));
  }
}

export function* asyncCreateOrderRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/orders',
      method: 'POST',
      params: payload,
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.createOrderSuccess(response.data));
    resolve();
  } catch(err) {
    yield put(adminActions.createOrderFailure(err));
    reject(err.message);
  }
}

export function* asyncGetOrdersSummary() {
  try {
    const [tradableRes, nonTradableRes] = yield all([
      call(ApiService, {
        api: '/api/admin/orders?limit=-1&isLocked=false',
        method: 'GET',
      }),
      call(ApiService, {
        api: '/api/admin/orders?limit=-1&isLocked=true',
        method: 'GET',
      }),
    ]);

    if(!tradableRes.success) {
      throw new Error(tradableRes.message);
    }

    if(!nonTradableRes.success) {
      throw new Error(nonTradableRes.message);
    }

    // calculate summary
    const tradableOrders = tradableRes.data.orders;
    const nonTradableOrders = nonTradableRes.data.orders;

    const TOTAL_TURNCOIN = 1000000000;
    const tradableIssuedTC = tradableOrders.filter((data) => data.status === 'ISSUED').reduce((sum, order) => sum + order.amount, 0);
    const tradableIssuedTCBought = tradableOrders.filter((data) => data.status === 'ISSUED' && data.method === 'Investment').reduce((sum, order) => sum + order.amount, 0);
    const tradableIssuedTCGiven = tradableIssuedTC - tradableIssuedTCBought;
    const tradablePendingTC = tradableOrders.filter((data) => data.status === 'PENDING').reduce((sum, order) => sum + order.amount, 0);
    const tradablePendingTCBought = tradableOrders.filter((data) => data.status === 'PENDING' && data.method === 'Investment').reduce((sum, order) => sum + order.amount, 0);
    const tradablePendingTCGiven = tradablePendingTC - tradablePendingTCBought;
    const tradableReservedTC = tradableOrders.filter((data) => data.status === 'RESERVED').reduce((sum, order) => sum + order.amount, 0);
    const tradableReservedTCBought = tradableOrders.filter((data) => data.status === 'RESERVED' && data.method === 'Investment').reduce((sum, order) => sum + order.amount, 0);
    const tradableReservedTCGiven = tradableReservedTC - tradableReservedTCBought;
    // const tradableTC = tradableIssuedTC + tradablePendingTC + tradableReservedTC;

    const nonTradableIssuedTC = nonTradableOrders.filter((data) => data.status === 'ISSUED').reduce((sum, order) => sum + order.amount, 0);
    const nonTradablePendingTC = nonTradableOrders.filter((data) => data.status === 'PENDING').reduce((sum, order) => sum + order.amount, 0);
    const nonTradableReservedTC = nonTradableOrders.filter((data) => data.status === 'RESERVED').reduce((sum, order) => sum + order.amount, 0);
    // const nonTradableTC = nonTradableIssuedTC + nonTradablePendingTC + nonTradableReservedTC;

    const issuedTC = tradableIssuedTC + nonTradableIssuedTC;
    const unissuedTC = TOTAL_TURNCOIN - issuedTC;

    const summary = {
      totalTC: TOTAL_TURNCOIN,
      tradableIssuedTC,
      tradableIssuedTCBought,
      tradableIssuedTCGiven,

      tradablePendingTC,
      tradablePendingTCBought,
      tradablePendingTCGiven,

      tradableReservedTC,
      tradableReservedTCBought,
      tradableReservedTCGiven,
      //
      nonTradableIssuedTC,
      nonTradablePendingTC,
      nonTradableReservedTC,
      issuedTC,
      unissuedTC,
    };

    yield put(adminActions.getOrdersSummarySuccess(summary));
  } catch(e) {
    yield put(adminActions.getOrdersSummaryFailure());
  }
}

export function* asyncGetInvestmentRoundInfo() {
  try {
    const response = yield call(ApiService, {
      api: '/api/admin/orders/investmentRound',
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(adminActions.getInvestmentRoundSuccess(response.data));
  } catch(err) {
    yield put(adminActions.getInvestmentRoundFailure(err));
  }
}

export function* watchGetUsersRequest() {
  while(true) {
    const action = yield take(GET_USERS_REQUEST);
    yield* asyncGetUsersRequest(action);
  }
}

export function* watchGetPendingUsersRequest() {
  while(true) {
    const action = yield take(GET_PENDING_USERS_REQUEST);
    yield* asyncGetPendingUsersRequest(action);
  }
}

export function* watchApprovePendingUserRequest() {
  while(true) {
    const action = yield take(APPROVE_PENDING_USER_REQUEST);
    yield* asyncApprovePendingUserRequest(action);
  }
}

export function* watchGetBlacklistUsersRequest() {
  while(true) {
    const action = yield take(GET_BLACKLIST_USERS_REQUEST);
    yield* asyncGetBlacklistUsersRequest(action);
  }
}

export function* watchBlacklistUserRequest() {
  while(true) {
    const action = yield take(BLACKLIST_USER_REQUEST);
    yield* asyncBlacklistUserRequest(action);
  }
}

export function* watchUnblacklistUserRequest() {
  while(true) {
    const action = yield take(UNBLACKLIST_USER_REQUEST);
    yield* asyncUnblacklistUserRequest(action);
  }
}

export function* watchGetUserLoginsRequest() {
  while(true) {
    const action = yield take(GET_USER_LOGINS_REQUEST);
    yield* asyncGetUserLoginsRequest(action);
  }
}

export function* watchGenerateCodeRequest() {
  while(true) {
    const action = yield take(GENERATE_CODE_REQUEST);
    yield* asyncGenerateCodeRequest(action);
  }
}

export function* watchGetRegistrationCodeRequest() {
  while(true) {
    const action = yield take(GET_REGISTRATION_CODE_REQUEST);
    yield* asyncGetRegistrationCodeRequest(action);
  }
}

export function* watchGetOrdersRequest() {
  yield takeEvery(GET_ALL_ORDERS_REQUEST, asyncGetOrdersRequest);
}

export function* watchUpdateUserOrderRequest() {
  while(true) {
    const action = yield take(UPDATE_USER_ORDER_STATUS_REQUEST);
    yield* asyncUpdateUserOrderRequest(action);
  }
}

export function* watchGetUserOrdersRequest() {
  while(true) {
    const action = yield take(GET_USER_ORDERS_REQUEST);
    yield* asyncGetUserOrdersRequest(action);
  }
}

export function* watchDeleteOrderRequest() {
  while(true) {
    const action = yield take(DELETE_ORDER_REQUEST);
    yield* asyncDeleteOrderRequest(action);
  }
}

export function* watchUpdateOrderRequest() {
  while(true) {
    const action = yield take(UPDATE_ORDER_REQUEST);
    yield* asyncUpdateOrderRequest(action);
  }
}

export function* watchUploadDocumentRequest() {
  while(true) {
    const action = yield take(UPLOAD_DOCUMENT_REQUEST);
    yield* asyncUploadDocumentRequest(action);
  }
}

export function* watchGetDocumentRequest() {
  while(true) {
    const action = yield take(GET_DOCUMENT_REQUEST);
    yield* asyncGetDocumentRequest(action);
  }
}

export function* watchDeleteDocumentRequest() {
  while(true) {
    const action = yield take(DELETE_DOCUMENT_REQUEST);
    yield* asyncDeleteDocumentRequest(action);
  }
}

export function* watchGetNewsRequest() {
  while(true) {
    const action = yield take(GET_NEWS_REQUEST);
    yield* asyncGetNewsRequest(action);
  }
}

export function* watchUploadPhotoRequest() {
  while(true) {
    const action = yield take(UPLOAD_PHOTO_REQUEST);
    yield* asyncUploadPhotoRequest(action);
  }
}

export function* watchCreateNewsRequest() {
  while(true) {
    const action = yield take(CREATE_NEWS_REQUEST);
    yield* asyncCreateNewsRequest(action);
  }
}

export function* watchDeleteNewsRequest() {
  while(true) {
    const action = yield take(DELETE_NEWS_REQUEST);
    yield* asyncDeleteNewsRequest(action);
  }
}

export function* watchGetNewsByIdRequest() {
  while(true) {
    const action = yield take(GET_NEWS_BY_ID_REQUEST);
    yield* asyncGetNewsByIdRequest(action);
  }
}

export function* watchUpdateNews() {
  while(true) {
    const action = yield take(UPDATE_NEWS_REQUEST);
    yield* asyncUpdateNews(action);
  }
}

export function* watchUpdateUserInfoRequest() {
  while(true) {
    const action = yield take(UPDATE_USER_INFO_REQUEST);
    yield* asyncUpdateUserInfoRequest(action);
  }
}

export function* watchGetUserByIdRequest() {
  while(true) {
    const action = yield take(GET_USER_BY_ID_REQUEST);
    yield* asyncGetUserByIdRequest(action);
  }
}

export function* watchCreateOrderRequest() {
  while(true) {
    const action = yield take(CREATE_ORDER_REQUEST);
    yield* asyncCreateOrderRequest(action);
  }
}

export function* watchGetOrdersByUserId() {
  while(true) {
    const action = yield take(GET_ORDERS_BY_USER_ID_REQUEST);
    yield* asyncGetOrdersByUserId(action);
  }
}

export function* watchGetOrdersSummary() {
  while(true) {
    const action = yield take(GET_ORDERS_SUMMARY_REQUEST);
    yield* asyncGetOrdersSummary(action);
  }
}

export function* watchGetGetInvestmentRoundInfo() {
  while(true) {
    const action = yield take(GET_INVESTMENT_ROUND_REQUEST);
    yield* asyncGetInvestmentRoundInfo(action);
  }
}

export default function* () {
  yield all([
    fork(watchGetUsersRequest),
    fork(watchGetPendingUsersRequest),
    fork(watchApprovePendingUserRequest),
    fork(watchGetBlacklistUsersRequest),
    fork(watchBlacklistUserRequest),
    fork(watchUnblacklistUserRequest),
    fork(watchGetUserLoginsRequest),
    fork(watchGenerateCodeRequest),
    fork(watchGetRegistrationCodeRequest),
    fork(watchGetOrdersRequest),
    fork(watchGetUserOrdersRequest),
    fork(watchUpdateUserOrderRequest),
    fork(watchDeleteOrderRequest),
    fork(watchUpdateOrderRequest),
    fork(watchUploadDocumentRequest),
    fork(watchGetDocumentRequest),
    fork(watchDeleteDocumentRequest),
    fork(watchGetNewsRequest),
    fork(watchUploadPhotoRequest),
    fork(watchCreateNewsRequest),
    fork(watchDeleteNewsRequest),
    fork(watchGetNewsByIdRequest),
    fork(watchUpdateNews),
    fork(watchUpdateUserInfoRequest),
    fork(watchGetUserByIdRequest),
    fork(watchCreateOrderRequest),
    fork(watchGetOrdersByUserId),
    fork(watchGetOrdersSummary),
    fork(watchGetGetInvestmentRoundInfo)
  ]);
}
