import React from 'react';
import { connect } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authActions } from '../../core';

class CreatePassword extends React.Component {
  state = {
    email: '',
    password: '',
    password2: '',
    errors: '',
    status: 'idle'
  };

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get('email') || this.props.auth.user.email;
    if(!email){
      this.props.history.push('/');
    }
    else {
      this.setState({email});
    }
  }

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  onSuccess = () => {
    this.props.history.push('/dashboard');
  };

  onFail = (response) => {
    this.setState({ errors: '', status: 'idle' });
    this.setState({ errors: response.message });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { password, password2 } = this.state;
    const { email } = this.state;
    if(password !== password2) {
      this.setState({ errors: 'Your password must match to confirm.' });
      return;
    }

    this.setState({ errors: '', status: 'running' });
    let params = {
      email,
      password,
    };
    this.props.createPassword(params, this.onSuccess, this.onFail);
  };

  isValid = () => {
    const { password, password2 } = this.state;
    if(!password || !password2) {
      return false;
    }

    return true;
  };

  render() {
    const { errors, status } = this.state;
    const { email } = this.state;

    return (
      <div className="register_body"> 
        <div className="poppins-font">
          <div className="xchange_copywrite_message">© TheXchange™ 2017-2021. All rights reserved.</div>
          <div className="register_body_wrapper">
            <img src="https://s3.us-east-2.amazonaws.com/s3public.turncoin.io/turncoin/full_logo.png" loading="lazy" alt="" className="register_page_logo" />
            
            <div className="login_form_block w-form">
              <p className="register_form_title" style={{color:'wheat'}}>Create a password for: {email}</p>
              <form id="wf-form-Turncoin-Register" name="wf-form-Turncoin-Register" data-name="Turncoin Register" onSubmit={this.handleSubmit}>
                <input type="password" onChange={this.onFieldChange} className="turncoin_registration_input_field w-input" 
                maxLength="256" name="password" data-name="Password" placeholder="Password" id="Password" required={true} />
                <input type="password" onChange={this.onFieldChange} className="turncoin_registration_input_field w-input" 
                maxLength="256" name="password2" data-name="Confirm Password" placeholder="Confirm Password" id="Confirm-Password" required={true}/>
              
                <div className="register_form_btn_wrapper">
                  <div className={`turncoin_register-page_btn ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}>
                    <input
                      type="submit"
                      value={status === 'idle'? "Submit": "Busy, please wait..."}
                      style={{backgroundColor: 'transparent', border: 0, width:'100%', height: '40px'}}
                      // data-wait="Please wait..."
                      // className={`turncoin_submit_action w-button ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}
                      // disabled={status === 'running' || !this.isValid()}
                    />
                  </div>
                </div>
              </form>
              {!(errors === '' || errors === undefined) && (
                  <div className="error_message">
                    {errors}
                  </div>
                )
              }
            </div>
            <Link to="/" className="turncoin_back_button w-inline-block" style={{color: 'wheat'}}>
              <div style={{paddingTop: '20px'}} className="text-block-6">Back</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

CreatePassword.propTypes = {
  history: PropTypes.object.isRequired,
  createPassword: PropTypes.func.isRequired,
};


function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    auth,
    isAuthenticated,
    status: auth.status,
  };
}

const mapDispatchToProps =(dispatch) => ({
    createPassword: (payload, resolve, reject) => dispatch(authActions.createPassword(payload, resolve, reject)),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePassword));
