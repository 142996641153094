import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';

class Welcome extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render () {
    return (
      <div>
        <Row gutter={[16, 16]} type="flex" align="middle" justify="center">
          <Col align="center">
            <Row type="flex" align="middle" justify="center">
              <Col lg={{ span: 2 }} />
              <Col lg={{ span: 20 }}>
                <h5>Welcome</h5>
              </Col>
              <Col lg={{ span: 2 }} />
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} type="flex" align="middle" justify="center">
          <Col align="center">
            <Row type="flex" align="middle" justify="center">
              <Col lg={{ span: 2 }} />
              <Col lg={{ span: 20 }}>
                <p>View your profile here</p>
              </Col>
              <Col lg={{ span: 2 }} />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Welcome;
