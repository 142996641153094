/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { ApiService } from '../../services/restService';
// import { countryList } from 'containers/Home/country_list';

class DefaultFooter extends PureComponent {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    message: '',
    contactUsErrors: '',
    status: 'idle',
    newsletterEmail: '',
    newsletterStatus: 'idle',
    newsletterErrors: '',
    isContactUsModal: false,
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, firstName, lastName, country, message } = this.state;
    this.setState({ status: 'running' });
    ApiService({
      api: '/api/contactUs',
      method: 'POST',
      params: {
        email,
        firstName,
        lastName,
        country,
        message,
      },
    })
      .then((data) => {
        if(data.success) {
          this.setState({ status: 'success', contactUsErrors: '' });
          setTimeout(() => {
            this.setState({ isContactUsModal: false });
          }, 1000);
        } else {
          this.setState({ contactUsErrors: data.message, status: 'idle' });
        }
      });
  };

  handleNewsletterSubmit = (e) => {
    e.preventDefault();
    const { newsletterEmail } = this.state;
    this.setState({ newsletterStatus: 'running' });
    ApiService({
      api: '/api/newsletter',
      method: 'POST',
      params: {
        email: newsletterEmail,
      },
    })
      .then((data) => {
        if(data.success) {
          this.setState({ newsletterStatus: 'success', newsletterErrors: '' });
          setTimeout(() => {
            this.setState({ newsletterStatus: 'idle' });
          }, 3000);
        } else {
          this.setState({ newsletterErrors: data.message, newsletterStatus: 'idle' });
        }
      });
  };

  render() {
    const { newsletterStatus, newsletterErrors } = this.state;
    return (
      <div className="app_footer">
        <footer className="turncoin_footer">
          {/* <a href="#hero" className="x__back-to-top_wrapper w-inline-block">
            <div>Back to top </div>
            <div className="x_btt_arrow"></div>
          </a> */}
          <div className="turncoin_container x__footer_section">
            <div className="x__footer_top_wrapper">
              <div className="x__footer_col_1"><img src="images/xchange-logo.svg" loading="lazy" alt="" className="x__footer_logo" />
                <div className="x__footer_text"><span className="x__footer_text_bold">Marina Bay Financial Towers</span><br />10 Marina Blvd, Tower 2, Singapore, 018983<br />
                  <a href="mailto:support@turncoin.io?subject=From%20Website">support@turncoin.io</a>
                </div>
              </div>
              <div className="x__footer_col_2">
                <div className="x__footer_text_bold">SIGN UP FOR OUR NEWSLETTER</div>
                <div className="x__newsletter_form">
                  <div id="newsletter-email" className="x__newsletter_form_block w-form">
                    <form id="wf-form-Newsletter-Email" name="wf-form-Newsletter-Email" data-name="Newsletter Email" className="x__newsletter_wrapper" onSubmit={this.handleNewsletterSubmit}>
                      <input type="email" onChange={this.onFieldChange} className="x__newsletter_input_form w-input" maxLength="256" name="newsletterEmail" data-name="Newsletter" placeholder="Your Email" id="Newsletter" required />
                      <input type="submit" value="Submit" data-wait="Please wait..." className="x__newsletter_submit_button w-button" />
                    </form>
                    {!(newsletterErrors === '' || newsletterErrors === undefined || newsletterStatus === 'running') && (
                      <div className="error_message">
                        {newsletterErrors}
                      </div>
                    )}
                    {newsletterStatus === 'success' && newsletterErrors === '' && (
                      <div className="success_message">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="x__footer_social_wrapper">
                  <div className="x__footer_text_bold hidden_mobile">connect with us </div>
                  <a href="https://www.facebook.com/VirtualStaX" target="_blank" rel="noopener noreferrer" className="x__footer_social_icon"></a>
                  <a href="https://twitter.com/VirtualStaX" target="_blank" rel="noopener noreferrer" className="x__footer_social_icon"></a>
                  <a href="https://www.instagram.com/virtualstax/" target="_blank" rel="noopener noreferrer" className="x__footer_social_icon"></a>
                </div>
              </div>
            </div>
            <div className="x__footer_mid_wrapper">
              <div>
                <span className="footer-links open-modal" style={{ cursor: 'pointer' }} onClick={() => this.setState({ isContactUsModal: true })}>Contact</span>
                | <a href="https://Enter:Password@terms.turncoin.io" target="_blank" rel="noopener noreferrer" className="footer-links">TERMS &amp; CONDITIONS </a>
                  <span className="phone_hidden"> | </span>
                <a href="https://Enter:Password@terms.turncoin.io" className="footer-links last" target="_blank" rel="noopener noreferrer">PRIVACY &amp; COOKIES</a>
              </div>
            </div>
          </div>
          <div className="x__footer_bottom_wrapper">
            <div>© TheXchange 2017-2021. All rights reserved.</div>
            <div className="x__btm_footer_disclaimer">
              <span className="turncoin_medium_text">Disclaimer:</span> The content of this website is protected by Copyright. 
              It is expressly provided that no commercial and/or personal association exists between the personalities used in the content of this website, 
              and the business activities of TheXchange Pte. Ltd, whether such personalities are referred to and/or alluded to by name, reference, figure, 
              silhouette and/or image. The images used in the content of this website is demonstrative by nature, and should not be perceived as 
              inferring a connection and/or relationship between TheXchange Pte. Ltd and the relevant personality.
              {/* <br /><br />
              <span className="turncoin_medium_text">THIS IS A PRIVATE WEBSITE, IT IS NOT OPEN TO THE PUBLIC.</span>
              <br /><br />
              <span className="turncoin_medium_text">The images used in the content of this website is demonstrative by nature, and should not be perceived as inferring a connection and/or relationship between TheXchange Pte. Ltd and the relevant personality.</span> */}
            </div>
          </div>
        </footer>
        <div className="x__form_popup" style={{ display: this.state.isContactUsModal ? 'block' : 'none' }}>
          <div className="x__popup_container">
            <div className="w-form">
              <div className="w-embed">
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                      .x__form_input_field {
                        -webkit-appearance: none;
                      }
                      .select_field_arrow {
                        pointer-events: none;
                      }
                    `,
                  }}
                />
              </div>
              <h2 className="x__form_headline" style={{ fontSize: 36, color: '#d0ad6a' }}>CONTACT US</h2>
              <div className="text-block-3" style={{ marginTop: 35, marginBottom: 26 }}>Please complete the form below &amp; submit</div>
              {/* <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form" onSubmit={this.handleSubmit}>
                <div className="x__select_field_wrapper">
                  <div style={{ paddingLeft: 18, paddingBottom: 18 }}><span style={{ color: '#d0ad6a' }}>*</span> Country</div>
                  <select id="field" name="country" className="x__form_input_field w-select" defaultValue="" onChange={this.onFieldChange} required>
                    <option style={{ backgroundColor: '#212121' }} value="">Country</option>
                    {countryList.map(country => (
                      <option style={{ backgroundColor: '#212121' }} value={country.name} key={country.code}>{country.name}</option>
                    ))}
                  </select>
                  <div className="select_field_arrow" style={{ top: '40%' }}></div>
                </div>
                <div className="x__form_2-col">
                  <div>
                    <div style={{ paddingLeft: 18, paddingBottom: 18 }}><span style={{ color: '#d0ad6a' }}>*</span> First name</div>
                    <input type="text" onChange={this.onFieldChange} className="x__form_input_field w-input" maxLength="256" name="firstName" data-name="First Name" placeholder="First Name" id="First-Name" required />
                  </div>
                  <div>
                    <div style={{ paddingLeft: 18, paddingBottom: 18 }}><span style={{ color: '#d0ad6a' }}>*</span> Last name</div>
                    <input type="text" onChange={this.onFieldChange} className="x__form_input_field w-input" maxLength="256" name="lastName" data-name="Last Name" placeholder="Last Name" id="Last-Name" required />
                  </div>
                </div>
                <div style={{ paddingLeft: 18, paddingBottom: 18 }}><span style={{ color: '#d0ad6a' }}>*</span> Email</div>
                <input type="email" onChange={this.onFieldChange} className="x__form_input_field w-input" maxLength="256" name="email" data-name="Email" placeholder="Email" id="email" required />
                <div style={{ paddingLeft: 18, paddingBottom: 18 }}><span style={{ color: '#d0ad6a' }}>*</span> Message</div>
                <textarea style={{ height: 96 }} onChange={this.onFieldChange} placeholder="Your message here..." maxLength="5000" id="Message" name="message" data-name="Message" className="x__form_input_field w-input" required />
                <div className="x__form_disclaimers">
                  <div style={{ paddingLeft: 18, paddingBottom: 16 }}><span className="x__gold_text">*</span> Compulsory fields</div>
                  <div>By submitting this form you agree to the <a href="https://Enter:Password@terms.turncoin.io" target="_blank" rel="noopener noreferrer" className="x__gold_text">Terms of Service</a> and <a href="https://Enter:Password@terms.turncoin.io" target="_blank" rel="noopener noreferrer" className="x__gold_text">Privacy Policy</a></div>
                </div>
                <div className={`x__button_w_arrow align_button_left ${status === 'running' && 'disable-btn'}`} data-w-id="994c57bf-76e6-334f-ec6b-ec76c1befbeb">
                  <input type="submit" value="Submit" data-wait="Please wait..." className="x__submit_button_trigger w-button" disabled={status === 'running'} />
                  <div className="x__submit_btn_plane"></div>
                  <div className="x__btn_w_arrow__text">SUBMIT</div>
                  <div style={{ width: 0 }} className="hover_line_bottom" />
                  <div style={{ height: 0 }} className="hover_line_right" />
                  <div style={{ width: 0 }} className="hover_line_top" />
                  <div style={{ height: 0 }} className="hover_line_left" />
                </div>
                {!(contactUsErrors === '' || contactUsErrors === undefined || status === 'running') && (
                  <div className="error_message">
                    {contactUsErrors}
                  </div>
                )}
                {status === 'success' && contactUsErrors === '' && (
                  <div className="success_message">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                )}
              </form> */}
            </div>
            <div id="close-modal" className="x__close_popup close-modal" onClick={() => this.setState({ isContactUsModal: false, status: 'idle', contactUsErrors: '' })}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default DefaultFooter;
