import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

class DashboardHelp extends React.PureComponent {
  render() {
    // const { user } = this.props;

    return (
      <div className="overview">
        <Row>
          <Col lg={13} sm={24}>
            <div className="notification">
              {/* <p>{`Hello ${user.firstName} ${user.lastName}!`}</p> */}
              {/* <br /> */}
              <p>Visit our FAQs for everything you need to know.</p>
              <br/><br/>
              <p>Need further assistance?<br/><br/>Please contact <a href="mailto:support@turncoin.com" target="_blank" rel="noopener noreferrer">support@turncoin.com</a> 
              <nbsp/> to receive additional assistance with your TurnCoin account.</p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

DashboardHelp.propTypes = {
  user: PropTypes.object,
};

DashboardHelp.defaultProps = {
  user: {
    firstName: '',
    lastName: '',
  },
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(DashboardHelp);
