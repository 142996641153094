import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const error = urlParams.get('error');

  return (
    <div className="login_body">
      <div className="login_body_wrapper" style={{textAlign: 'center'}}>
        <img src="images/thexchange-logo.svg" loading="lazy" alt="" className="login_page_logo" />
      </div>
      <div style={{ fontSize: 60, textAlign: 'center' }}>
        <p style={{color: 'white'}}>Sorry, an error has occured. <Link to="/">Go Home</Link></p>
        <p style={{color: 'white'}}>{error}</p>
      </div>
    </div>
  );
}

export default ErrorPage;
