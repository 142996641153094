import React from 'react';
import PersonalInfoTable from '../../components/Dashboard/PersonalInfoTable';

class PersonalInfo extends React.PureComponent {
  render() {
    return (
      <PersonalInfoTable />
    );
  }
}

export default PersonalInfo;
