import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import { authActions } from "../../core";
import "../../assets/styles/components/Forms/Login.less";

class CheckEmail extends React.PureComponent {
  state = {
    email: "",
    remember: false,
    validated: false,
    errors: "",
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    //call check on email
    this.props.signUp({ email }, this.onSuccess, this.onFail);
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCheckboxChange = (e) => {
    this.setState({ agreement: e.target.checked });
  };

  toggleRemember = () => {
    this.setState({ remember: !this.state.remember });
  };

  onSuccess = (data) => {
    //const { from } = this.props.location.state || { from: { pathname: '/' } };
    //this.props.history.push(from);
 if(data.state === 0) {
  this.props.history.push("/register-success");
} else if(data.state === 1) {
  this.props.history.push("/create-password");
} else if(data.state === 2) {
  this.props.history.push("/login");
} 
  };

  onFail = (response) => {
    this.setState({
      errors: response.message,
    });
  };

  render() {
    const { errors } = this.state;
    const { status } = this.props;

    if(this.props.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="login_body">
        <div className="poppins-font">
          <div className="login_body_wrapper">
            <img
              src="https://s3.us-east-2.amazonaws.com/s3public.turncoin.io/turncoin/full_logo.png"
              loading="lazy"
              alt=""
              className="login_page_logo"
            />
            <h4 className="login_form_title" style={{ color: "wheat" }}>
              LOGIN/REGISTER
            </h4>
            <div className="login_form_block w-form">
              <form
                id="wf-form-Turncoin-Login"
                name="wf-form-Turncoin-Login"
                data-name="Turncoin Login"
                onSubmit={this.handleSubmit}
              >
                {status === "idle" ? (
                  <div>
                    <input
                      type="email"
                      onChange={this.onFieldChange}
                      maxLength="256"
                      style={{
                        width: "100%",
                        padding: "10px",
                        color: "black",
                        borderRadius: "5px",
                      }}
                      name="email"
                      data-name="Turncoin Email"
                      placeholder="Email"
                      id="Turncoin-Email"
                      required
                    />

                    <div
                      className="register_form_btn_wrapper"
                      style={{ paddingTop: "20px" }}
                    >
                      <div className="turncoin_register-page_btn">
                        <input
                          type="submit"
                          value="Login"
                          style={{
                            backgroundColor: "transparent",
                            border: 0,
                            width: "100%",
                            height: "40px",
                          }}
                          data-wait="Please wait..."
                          // className={`turncoin_submit_action w-button ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}
                          disabled={status === "running" ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="login_form_title">Busy, please wait...</p>
                  </div>
                )}
              </form>
            </div>
            {!(
              errors === "" ||
              errors === undefined ||
              status === "running"
            ) && (
              <div className="error_message">
                <p>{errors}</p>
              </div>
            )}
            {/* <div>Oops! Something went wrong while submitting the form.</div> */}
          </div>
          <div className="xchange_copywrite_message">
            <p className="login_page_paragraph">
              By accessing this website I agree to be bound by the
              <a
                href="https://turncoin.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="login_form_p_links"
              >
                {" "}
                Terms &amp; Conditions, Confidentiality Agreement
              </a>{" "}
              and
              <a
                href="https://turncoin.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="login_form_p_links"
              >
                {" "}
                Privacy Policy
              </a>{" "}
              of TheXchange and all of its SubXchanges &amp; associated
              websites.
            </p>
            © TheXchange™ 2017-2023. All rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

CheckEmail.propTypes = {
  status: PropTypes.string,
  history: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object,
};

CheckEmail.defaultProps = {
  status: "idle",
};

function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    auth,
    isAuthenticated,
    status: auth.status,
  };
}

const mapDispatchToProps = (dispatch) => ({
  signUp: (payload, resolve, reject) =>
    dispatch(authActions.signUp(payload, resolve, reject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckEmail);
