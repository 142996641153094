import React from 'react';
import OrdersTable from '../../components/Dashboard/OrdersTable';

class Orders extends React.PureComponent {
  render() {
    return (
      <OrdersTable />
    );
  }
}

export default Orders;
