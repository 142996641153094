/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Descriptions, Button, Modal, Input, Form, message, Spin, DatePicker, Select } from 'antd';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { adminActions } from '../../core';
import BackendTableOrders from './BackendTableOrders';
import OrderModal from './OrderModal';
//import { getFieldsData } from '../../utilities';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

class EditUserModal extends PureComponent {
  constructor(props){
    super(props);
    this.editRef = React.createRef();
    this.initalState = {
    };
    this.cloneState = _.cloneDeep(this.initalState);
    this.state = this.cloneState;
  }

  checkEntityType = (value) => {
   if(value === 'Individual'){
    this.setState({isIndividual: true});
   }
   else {
    this.setState({isIndividual: false});
   }
  };

  handleFormValuesChange = (changedValues) => {
    const { defaultValues } = this.props;
    const formFieldName = Object.keys(changedValues)[0];
    if(formFieldName === "entityType") {
      this.setState({isIndividual: defaultValues.entityType === "Individual" ? true : false});
    }
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { visible, onCancel, onSubmit, defaultValues } = this.props;
    console.log(this.state, defaultValues);
    return (
      <Modal
        visible={visible}
        title="EDIT USER"
        okText="Update"
        onCancel={onCancel}
        onOk={onSubmit}
        centered
      >
        <Form
          {...layout}
          ref={this.editRef}
          initialValues={{
            dateAdded: moment(defaultValues.dateAdded),
            firstName: defaultValues.firstName,
            lastName: defaultValues.lastName,
            entityName: defaultValues.entityName,
            entityType: defaultValues.entityType,
            title: defaultValues.title,
            email: defaultValues.email,
            phonePrefix: defaultValues.phonePrefix,
            phone: defaultValues.phone,
            isIndividual: defaultValues.entityType === "Individual" ? true : false,
          }}
          onFieldsChange={this.handleFormValuesChange}
        >

          <Form.Item
            name="dateAdded"
            label="Date Added"
            rules={[{ required: true, message: 'Please select the date!' }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input the first name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input the last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="entityType"
            label="Entity Type"
          >
             <Select onChange={this.checkEntityType}>
                <Select.Option value="Individual">Individual</Select.Option>
                <Select.Option value="Company - Private">Company - Private</Select.Option>
                <Select.Option value="Company - Public">Company - Public</Select.Option>
                <Select.Option value="Corporation/Other">Corporation/Other</Select.Option>
                <Select.Option value="Foundation">Foundation</Select.Option>
                <Select.Option value="Trust">Trust</Select.Option>
              </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
          >
            {({ getFieldValue }) =>
            getFieldValue('entityType') !== 'Individual' ? ( 
            <Form.Item
              name="entityName"
              label="Entity Name"
              style={this.state.isIndividual ? { display: 'none'} : {display: 'flex'}}
            >
              <Input />
            </Form.Item>) : null
            }
          </Form.Item>
         
          <Form.Item
            name="title"
            label="Title"
          >
             <Select>
                <Select.Option value="Mr">Mr</Select.Option>
                <Select.Option value="Mrs">Mrs</Select.Option>
                <Select.Option value="Ms">Ms</Select.Option>
                <Select.Option value="Dr">Dr</Select.Option>
                <Select.Option value="Prof">Prof</Select.Option>
              </Select>
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email', message: 'Please enter a valid email address.' },
              { required: true, message: 'Please input the email!' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Phone">
            <Input.Group compact>
              <Form.Item
                name="phonePrefix"
                noStyle
                rules={[{ required: true, message: 'Country code is required' }]}
              >
                <Input  style={{ width: '30%' }} type="tel" />
              </Form.Item>
              <Form.Item
                name="phone"
                noStyle
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input type="tel" style={{ width: '70%' }} pattern="[+]{2}-[0-9]{2}-[0-9]{3}" />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          {/*<Form.Item
            label="TurnCoin Holdings"
          >
            <span>{defaultValues.turncoinHolding}</span>
          </Form.Item>*/}
        </Form>
      </Modal>
    );
  }
}


EditUserModal.propTypes = {
  defaultValues: PropTypes.shape({
    dateAdded: PropTypes.instanceOf(Date),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    entityName: PropTypes.string,
    entityType: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    turncoinHolding: PropTypes.number,
  }),
};

EditUserModal.defaultProps = {
  defaultValues: {},
};


class BackendUserPageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visible1: false,
      editOrderVisible: false,
      order: {},
    };
    this.userModalRef = React.createRef();
    this.orderModalRef = React.createRef();
    this.editOrderModalRef = React.createRef();
  }

  componentDidMount() {
    const { match, getUserById } = this.props;
    const { userId } = match.params;
    getUserById({ userId });
  }

  editUserModalRef = (ref) => {
    this.userModalRef = ref;
  };

  handleUpdateUserInfo = () => {
    const { editRef } = this.userModalRef.current;
    const { match, updateUserInfo } = this.props;
    const { userId } = match.params;
    editRef.current.validateFields().then((values) => {
      updateUserInfo(
        { userId, ...values, dateAdded: values.dateAdded.utc().format() },
        (msg) => {
          message.success(msg);
          editRef.current.resetFields();
          this.setState({ visible: false });
        },
        (errors) => {
          /*console.log(errors);
          const fields = getFieldsData(values, errors);
          editRef.current.setFields(fields);*/
          message.error(`Update failed: ${JSON.stringify(errors)}`);
        },
      );
    });
  };

  handleCancel = () => {
    // const { form } = this.userModalRef.current
    this.setState({ visible: false });
  };

  closeEditOrderModal =() => {
    this.setState({ editOrderVisible: false });
  };

  closeCreateOrderModal = () => {
    //const { orderRef } = this.orderModalRef.current;
    //orderRef.current.resetFields();
    this.setState({ visible1: false });
  };

  /*handleCreateOrder = () => {
    const { orderRef } = this.orderModalRef.current;
    const { match, createOrder } = this.props;
    const { userId } = match.params;

    orderRef.current.validateFields().then((values) => {
      createOrder(
        { ...values, user: userId },
        () => { 
          this.setState({ visible1: false });
          orderRef.current.resetFields();
          message.success('The Order was successfully created');
        },
        (errMsg) => { message.error(errMsg) },
      );
      
    });
  };*/

  setOrder = (order) => {
    this.setState({ editOrderVisible: true });
    if(order) this.setState({ order });
  };

  /*handleUpdateOrder = () => {
    const { orderRef } = this.editOrderModalRef.current;
    const { match } = this.props;
    const { userId } = match.params;

    orderRef.current.validateFields().then((values) => {
      const params = {
        ...values,
        orderId: values.id,
        userId,
        orderTimestamp: values.orderTimestamp.format(),
      };
      if(values.status === 'DELETE') {
        this.props.deleteOrder(
          { id: values.id },
          () => {
            message.success('The Order was successfully deleted');
            this.setState({ editOrderVisible: false });
            this.props.getUserById({ userId });
          },
          (errMsg) => { message.error(errMsg) },
        );
      } else {
        this.props.updateOrder(
          params,
          () => {
            message.success('The Order was successfully updated');
            this.setState({ editOrderVisible: false });
            this.props.getUserById({ userId });
          },
          (errMsg) => { message.error(errMsg) },
        );
      }
    });
  };*/

  render() {
    const { userInfo, status } = this.props;
    const { visible, visible1, editOrderVisible } = this.state;
    const { user: userProfile, orders: userOrders } = userInfo;
    let userTurncoin = 0;
    userOrders.forEach((order) => {
      if(order.status === 'ISSUED') userTurncoin += order.amount;
    });

    return (
      <div className="user-details-block-wrapper">
        <Button
          className="turncoin-btn"
          onClick={() => this.setState({ visible: true })}
        >
          Edit User
        </Button>
        <Spin spinning={status === 'running'}>
          <Descriptions bordered title="User Info" column={1} className="user-details-content">            
            <Descriptions.Item label="First Name">{userProfile.firstName}</Descriptions.Item>
            <Descriptions.Item label="Last Name">{userProfile.lastName}</Descriptions.Item>
            <Descriptions.Item label="Account Type">{userProfile.accountType}</Descriptions.Item>
            {userProfile.accountType == 'Entity' && (
              <>
                <Descriptions.Item label="Entity Name">{userProfile.entityName}</Descriptions.Item>
                <Descriptions.Item label="EntityType">{userProfile.entityType}</Descriptions.Item>
              </>
            )}
            
            <Descriptions.Item label="Title">{userProfile.title}</Descriptions.Item>
            <Descriptions.Item label="Email">{userProfile.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{userProfile.phonePrefix + userProfile.phone}</Descriptions.Item>
            <Descriptions.Item label="TurnCoin">{userTurncoin}</Descriptions.Item>

            
            <Descriptions.Item label="address">{userProfile.address}</Descriptions.Item>
            <Descriptions.Item label="city">{userProfile.city}</Descriptions.Item>
            <Descriptions.Item label="state">{userProfile.state}</Descriptions.Item>
            <Descriptions.Item label="zip">{userProfile.zip}</Descriptions.Item>
            <Descriptions.Item label="country">{userProfile.country}</Descriptions.Item>

            <Descriptions.Item label="kycStatus">{userProfile.kycStatus}</Descriptions.Item>
            {userProfile.accountType == 'Entity' && (
              <>
                <Descriptions.Item label="kybStatus">{userProfile.kybStatus}</Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="accreditationStatus">{userProfile.accreditationStatus}</Descriptions.Item>
            <Descriptions.Item label="referralCode">{userProfile.referralCode}</Descriptions.Item>
            <Descriptions.Item label="referredBy">{userProfile.referrer}</Descriptions.Item>
            <Descriptions.Item label="Investor ID">{userProfile.externalId}</Descriptions.Item>
          </Descriptions>
        </Spin>
        <Button
          className="turncoin-btn"
          onClick={() => this.setState({ visible1: true })}
        >Create Order
        </Button>
        <BackendTableOrders orders={userOrders} onSelectOrder={this.setOrder} hiddenEmailField />
        <EditUserModal
          ref={this.userModalRef}
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleUpdateUserInfo}
          defaultValues={{ ...userProfile, turncoinHolding: userTurncoin }}
        />
        <OrderModal
          layout={layout}
          //innerRef={this.orderModalRef}
          visible={visible1}
          onCancel={this.closeCreateOrderModal}
          //onSubmit={this.handleCreateOrder}
          initValues={{ ...userProfile }}
          update={false}
        />
        {/** Below is for editing an order */}
        {Object.keys(this.state.order).length > 0 &&
        (<OrderModal
          layout={layout}
          //ref={this.editOrderModalRef}
          visible={editOrderVisible}
          onCancel={this.closeEditOrderModal}
          //onSubmit={this.handleUpdateOrder}
          defaultValues={Object.assign(userProfile, this.state.order)}
          update
        />)}
      </div>
    );
  }
}

BackendUserPageDetails.propTypes = {
  status: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  //createOrder: PropTypes.func.isRequired,
  //updateOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    dateAdded: PropTypes.instanceOf(Date),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    entityName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    turncoinHolding: PropTypes.number,
    accountType: PropTypes.string,
    investorId: PropTypes.number,
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    state: PropTypes.string,
    kycStatus: PropTypes.string,
    accreditationStatus: PropTypes.string,
    kybStatus: PropTypes.string,
    entityType: PropTypes.string,
    referralCode: PropTypes.string,
    referrer: PropTypes.string,
  }),
};

BackendUserPageDetails.defaultProps = {
  defaultValues:{},
};

const mapStateToProps = ({ admin }) => ({
  //userOrders: admin.userOrders,
  userInfo: admin.ordersByUserId,
  status: admin.status,
});

const mapDispatchToProps = {
  updateUserInfo: adminActions.updateUserInfoRequest,
  getUserById: adminActions.getOrdersByUserIdRequest,
  //createOrder: adminActions.createOrderRequest,
  deleteOrder: adminActions.deleteOrderRequest,
  //updateOrder: adminActions.updateOrderRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackendUserPageDetails);
