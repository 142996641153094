import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Divider } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { orderActions } from '../../core';
import { numberFormatter } from '../../utilities';
import OrdersPagination from './OrdersPagination';
import { colors } from '../Backend/SelectStatus';
import { propTypes } from 'react-bootstrap/esm/Image';

function CustomTitle({ title }) {
  return (
    <span style={{ fontSize: 18, color: '#07174A', fontWeight: 'bold', textAlign: 'center', display: 'inline-block', marginBottom: 20 }}>
      {title}
    </span>
  );
}

CustomTitle.propTypes = {
  title: PropTypes.string,
  
};
CustomTitle.defaultProps = {
};

function CustomCell(text) {
  return (
    <span style={{ color: 'black' }}>
      {
      // numberFormatter(text)
      numberFormatter(text, 0)
    }
    </span>
  );
}

function PriceCell(text) {
  return (
    <span style={{ color: 'black' }}>
      {
      // Number(Number(text).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})
      numberFormatter(text, 2)
    }
    </span>
  );
}

function DateCell(text) {
  return (
    <span style={{ color: 'black' }}>
      {moment(text).format('MMM DD, YYYY')}
    </span>
  );
}

const columns = [
  {
    title: <CustomTitle title="Date" />,
    dataIndex: 'orderTimestamp',
    render: DateCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Turncoin" />,
    dataIndex: 'amount',
    render: CustomCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Price" />,
    dataIndex: 'itemCost',
    render: PriceCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Status" />,
    dataIndex: 'status',
    render: (text) => (
      <div
        style={{
          backgroundColor: colors[text.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ color: 'white', fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Dollar Total" />,
    dataIndex: 'total',
    render: PriceCell,
    align: 'center',
    className: 'column-status',
    width: 300,
  },
  {
    title: <CustomTitle title="Investment Round" />,
    dataIndex: 'investmentRound',
    render: (text) => (
      <div
        style={{
          //backgroundColor: colors[text.name.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{text.name}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 300,
  },
  {
    title: <CustomTitle title="Transaction Type" />,
    dataIndex: 'transactionType',
    render: (text) => (
      <div
        style={{
          //backgroundColor: colors[text.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{text}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Tradable/Non-Tradable" />,
    dataIndex: 'isLocked',
    render: (data) => (
      <div
        style={{
          //backgroundColor: colors[text.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{data? "Non-Tradable" : "Tradable"}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 250,
  },
];


const earnedColumns = [
  {
    title: <CustomTitle title="Date" />,
    dataIndex: 'orderTimestamp',
    render: DateCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Turncoin" />,
    dataIndex: 'amount',
    render: CustomCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Status" />,
    dataIndex: 'status',
    render: (text) => (
      <div
        style={{
          backgroundColor: colors[text.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span style={{ color: 'white', fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Vestment Date" />,
    dataIndex: 'vestmentDate',
    render: DateCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Tradable/Non-Tradable" />,
    dataIndex: 'isLocked',
    render: (data) => (
      <div
        style={{
          //backgroundColor: colors[text.toLowerCase()],
          height: 48,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{data? "Non-Tradable" : "Tradable"}</span>
      </div>
    ),
    align: 'center',
    className: 'column-status',
    width: 250,
  },
];

function MobileTable({ orders }) {
  return (
    <div className="mb-order-table table-responsive">
      <span className="" style={{display: 'flex', justifyContent: 'center', fontSize: '25px', fontWeight: '500'}}>Orders</span>
      <br/>
      {orders.map((order) => (
        <div className="order" key={order.id}>
          <div className="order-item">
            <span className="label">Order:</span>
            <span className="value" style={{ color: '#2196f3' }}>{`#${order.id}`}</span>
          </div>
          <div className="order-item">
            <span className="label">Date:</span>
            {moment(order.orderTimestamp).format('MMM DD, YYYY')}
          </div>
          <div className="order-item">
            <span className="label">Status:</span>
            <div
              style={{
                // backgroundColor: colors[order.status.toLowerCase()],
                height: 48,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span style={{ color: colors[order.status.toLowerCase()], fontWeight: 'bold' }}>{order.status}</span>
            </div>
          </div>
          <div className="order-item">
            <span className="label">Total:</span>
            <span className="value">{`${numberFormatter(order.total, 2)} for ${numberFormatter(order.amount)} items`}</span>
          </div>
          <div className="order-item">
            <span className="label">Price:</span>
            <span className="value">{`${numberFormatter(order.itemCost, 2)}`}</span>
          </div>
          <div className="order-item">
            <span className="label">Investment Round:</span>
            <span className="value">{order.investmentRound.name}</span>
          </div>
          <div className="order-item">
            <span className="label">Transaction Type:</span>
            <span className="value">{order.transactionType}</span>
          </div>
          {order.vestmentDate ? (
            <div className="order-item">
              <span className="label">Vestment date:</span>
              <span className="value">{moment(order.vestmentDate).format('MMM DD, YYYY')}</span>
            </div>
          ):<></>}
          <div className="order-item">
            <span className="label">Tradable/Non-Tradable</span>
            <span className="value">{order.isLocked ? "Non-Tradable":"Tradable"}</span>
          </div>
        </div>
        
      ))}
    </div>
  );
}


MobileTable.propTypes = {
  //orders: PropTypes.array,
  orders: PropTypes.shape({
    map: propTypes.func,
  }),
};

MobileTable.defaultProps = {
};

class OrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 768,
    };
    this.scrollTimer = null;
    window.onresize = this.changeTableType;
  }

  componentDidMount() {
    this.changePage(0);
  }

  changeTableType = () => {
    if(window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  };

  changePage = (page) => {
    this.props.getOrders({
      page,
      limit: this.props.pagination.limit,
    });
  };

  render() {
    const { orders, pagination, status } = this.props;
    const investmentOrders = orders.filter((order) => order.method === 'Investment');
    const earnedOrders = orders.filter((order ) => order.method === 'Earned');
    const { isMobile } = this.state;
    console.log(orders);
    return (
      <div className="order-table-container">
        <div className="order-table">
          <div className="order-table-wrapper">
            {isMobile ? (
              <MobileTable orders={orders} />
            ) : (
              <>
                    {investmentOrders.length > 0 && (<>
                      <Divider />
                      <div className="ant-descriptions-title">Investments</div>
                      <Table
                        columns={columns}
                        dataSource={investmentOrders}
                        rowKey="id"
                        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
                        pagination={false}
                        expandIconAsCell={false}
                        expandIconColumnIndex={isMobile ? 0 : columns.length - 1}
                        loading={status !== 'idle'} /></>)}

                    {earnedOrders.length > 0 && (<>
                      <Divider />
                      <div className="ant-descriptions-title">Turncoin</div>
                      <Table
                        columns={earnedColumns}
                        dataSource={earnedOrders}
                        rowKey="id"
                        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
                        pagination={false}
                        expandIconAsCell={false}
                        expandIconColumnIndex={isMobile ? 0 : columns.length - 1}
                        loading={status !== 'idle'} /></>)}
                  </>
                  )}
                  </div>
                <OrdersPagination total={pagination.total} pageSize={pagination.limit} onChange={this.changePage} />
        </div>
      </div>
    );
  }
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
  pagination: PropTypes.object,
  status: PropTypes.string,
  title: PropTypes.string,
  getOrders: PropTypes.func.isRequired,
};

OrdersTable.defaultProps = {
  orders: [],
  pagination: {
    limit: 10,
    total: 0,
    page: 1,
  },
  status: 'idle',
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  pagination: state.order.pagination,
  status: state.order.status,
});

const mapDispatchToProps = (dispatch) => ({
  getOrders: (payload) => dispatch(orderActions.getOrdersRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
