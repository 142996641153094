import {
  put,
  call,
  fork,
  all,
  take,
} from 'redux-saga/effects';

import { message } from 'antd';

import {
  authActions,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  GET_USER_INFO_REQUEST,
  UPDATE_PERSONAL_INFO_REQUEST,
  SIGNUP_REQUEST,
  CREATE_PASSWORD_REQUEST,
} from './actions';

import { ApiService } from '../../../services';

export function* asyncCreatePasswordRequest({ payload, resolve, reject }) {
  const { email, password } = payload;
  try {
    const response = yield call(ApiService, {
      api: '/api/auth/create-password',
      method: 'POST',
      params: { email,password },
    });
    if(response.success) {
      yield put(authActions.createPasswordSuccess({ user: response.data }));
      resolve(response);
    } else {
      yield put(authActions.createPasswordFailure());
      reject(response);
    }
  } catch(err) {
    yield put(authActions.createPasswordFailure({ message: err.message }));
    reject(err);
  }
}


export function* asyncLoginRequest({ payload, resolve, reject }) {
  const { email, password } = payload;
  try {
    const response = yield call(ApiService, {
      api: '/api/auth/login',
      method: 'POST',
      params: { email, password },
    });
    if(response.success) {
      yield put(authActions.loginSuccess({ user: response.data }));
      resolve(response);
    } else {
      yield put(authActions.loginFailure({ user: response.data }));
      reject(response);
    }
  } catch(err) {
    yield put(authActions.loginFailure({ message: err.message }));
    reject(err);
  }
}

export function* asyncSignupRequest({ payload, resolve, reject }) {
  const { email } = payload;
  try {
    const response = yield call(ApiService, {
      api: '/api/auth/signup',
      method: 'POST',
      params: {
        email
      },
    });
    // @TODO: Open next lines after login api is completed
    //check response to see if they have a password/validated email
    //if validated email check password
    //if password take them to login
    if(response.success) {
      yield put(authActions.signUpSuccess({ user: response.user, signUpState: response.state }));
      resolve(response);
    } else {
      yield put(authActions.signUpFailure());
      reject(response);
    }
  } catch(e) {
    reject(e);
  }
}

export function* asyncLogoutRequest({ resolve }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/auth/logout',
      method: 'GET',
    });
    yield put(authActions.logoutSuccess(response));
    resolve();
  } catch(err) {
    yield put(authActions.logoutFailure({
      message: err.message,
    }));
    //reject();
  }
}

export function* asyncGetUserInfoRequest() {
  try {
    const response = yield call(ApiService, {
      api: '/api/user',
      method: 'GET',
    });
    if(response.success) {
      yield put(authActions.getUserInfoSuccess(response));
    } else {
      yield put(authActions.getUserInfoFailure({ message: 'error' }));
    }
  } catch(err) {
    yield put(authActions.getUserInfoFailure({ message: err.message }));
  }
}

export function* asyncUpdatePersonalInfoRequest({ payload }) {
  try {
    const response = yield call(ApiService, {
      api: '/api/user',
      method: 'PUT',
      params: payload,
    });

    if(!response.success) {
      if(response.errors) {
        yield put(authActions.updatePersonalInfoFailure());
      } else {
        throw new Error(response.message);
      }
    } else {
      yield put(authActions.updatePersonalInfoSuccess(response.data));
      message.success('Update Successful!');

    }
  } catch(err) {
    yield put(authActions.updatePersonalInfoFailure({}));
    message.error(err.message);
  }
}
export function* watchCreatePasswordRequest() {
  while(true) {
    const action = yield take(CREATE_PASSWORD_REQUEST);
    yield* asyncCreatePasswordRequest(action);
  }
}

export function* watchLoginRequest() {
  while(true) {
    const action = yield take(LOGIN_REQUEST);
    yield* asyncLoginRequest(action);
  }
}

export function* watchSignUpRequest() {
  while(true) {
    const action = yield take(SIGNUP_REQUEST);
    yield* asyncSignupRequest(action);
  }
}

export function* watchLogoutRequest() {
  while(true) {
    const action = yield take(LOGOUT_REQUEST);
    yield* asyncLogoutRequest(action);
  }
}

export function* watchGetUserInfoRequest() {
  while(true) {
    const action = yield take(GET_USER_INFO_REQUEST);
    yield* asyncGetUserInfoRequest(action);
  }
}

export function* watchUpdatePersonalInfoRequest() {
  while(true) {
    const action = yield take(UPDATE_PERSONAL_INFO_REQUEST);
    yield* asyncUpdatePersonalInfoRequest(action);
  }
}

export default function* () {
  yield all([
    fork(watchCreatePasswordRequest),
    fork(watchLoginRequest),
    fork(watchSignUpRequest),
    fork(watchLogoutRequest),
    fork(watchGetUserInfoRequest),
    fork(watchUpdatePersonalInfoRequest),
  ]);
}
