// @flow

import { fork, all } from 'redux-saga/effects';
import {
  authSaga,
  orderSaga,
  adminSaga,
} from '../modules';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(orderSaga),
    fork(adminSaga),
  ]);
}
