import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Modal } from 'antd';
import { ApiService } from '../../services/restService';

// import { orderActions } from '../../core';
// import OverViewChart from 'components/Dashboard/OverViewChart';
// import PendingOrdersTable from 'components/Dashboard/PendingOrdersTable';

class OverView extends React.PureComponent {
  state = {
    user: true,
  };

  componentDidMount(){
    const { user } = this.props;

    const arr = ["firstName", "lastName", "phone", "country", "state", "address"];

    let hasAllKeys = true;
    arr.forEach((item) => {
      if( user[item] === undefined || user[item] === null || user[item] === '' ) {
        hasAllKeys = false;
      }
    });

    hasAllKeys ? this.setState({user: true}) : this.setState({user: false});
  }

  showModal() {
    Modal.success({
      title: 'Enquiry Sent!',
      okButtonProps: {style: { background: '#d0ad6a', color: 'black', borderColor: '#d0ad6a' }},
      //okButtonProps: {className: 'turncoin-btn'},
      content: (
        <div>
          <p>Thank you!</p>
          <br/>
          <p>Your contact request has been sent. You will receive an email from our team confirming the next steps to conclude your TurnCoin investment.</p>
          <br/>
          <p>Kind Regards,</p>
          <p>The TurnCoin Team!</p>
        </div>
      )
    });
  }

  handleOk = () => {
    this.setState({ status: false });
  };

  sendEmail = (e) => {
    e.preventDefault();
    const { email, firstName, lastName, country, phonePrefix, phone } = this.props.user;
    ApiService({
      api: '/api/consultation',
      method: 'POST',
      params: {
        email,
        firstName,
        lastName,
        country,
        phonePrefix,
        phone,
      },
    })
      .then((data) => {
        if(data.success) {
          this.showModal();
        } else {
          Modal.error({
            title: 'Failed!',
            content: 'Sorry, something went wrong.',
          });
        }
      });
  };

  render() {
    const { user } = this.props;

    return (
      <div className="overview">
        {(!this.state.user || !user.firstName || !user.lastName) && (<Row>
          <Col lg={13} sm={24}>
            <div className="notification">
              <p>
                Hello<br/><br/>
                Welcome to TurnCoin!<br/><br/>
                Please create your TurnCoin Account <Link to="/dashboard/personalInfo">here</Link> in order to proceed to the TurnCoin Investment Opportunity.<br/><br/>
                You will be required to complete your KYC / KYB verification in order to invest in TurnCoin.
                Our team of Investor Relations Specialists will contact you to facilitate this process.
              </p>
            </div>
          </Col>
        </Row>)}
        {this.state.user && (<Row>
          <Col lg={13} sm={24}>
            <div className="notification">
              <p>Hello <span className="notification-user-name">{`${user.firstName} ${user.lastName}!`}</span></p>
              <br/><br/>
              <p>Welcome to your TurnCoin account dashboard!<br/><br/></p>
              <p>
                You can view your&nbsp;
                <Link to="/dashboard/orders">Turncoin orders</Link> and <Link to="/dashboard/yields">yield credits</Link> on the left side panel.
                <br/>
                <br/>
                Should you wish to reserve TurnCoin at the current trading price, click the button below for one of our TurnCoin Specialists to assist you with the next steps.
              </p>
              <div style={{height: '50px', display: 'block'}}></div>
              <button className="turncoin-btn" type="button" onClick={this.sendEmail.bind(this)}>Send</button>
            </div>
          </Col>
        </Row>)}
        {/* <Row gutter={60}>
          <Col lg={12} sm={24}>
            <OverViewChart />
          </Col>
          <Col lg={8} sm={24}>
            <PendingOrdersTable orders={orders} />
          </Col>
        </Row> */}
      </div>
    );
  }
}

OverView.propTypes = {
  // getOrders: PropTypes.func.isRequired,
  user: PropTypes.object,
  // orders: PropTypes.array,
};

OverView.defaultProps = {
  user: {
    firstName: '',
    lastName: '',
  },
  // orders: [],
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  // orders: state.order.orders,
});

export default connect(mapStateToProps)(OverView);
