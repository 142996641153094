import _ from 'lodash';

import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,

  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,

  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,

  UPDATE_PERSONAL_INFO_REQUEST,
  UPDATE_PERSONAL_INFO_SUCCESS,
  UPDATE_PERSONAL_INFO_FAILURE,

} from './actions';
import { initialState } from '../initialState';

export default function auth(state = initialState.auth, action = {}) {
  const { type, payload } = action;

  switch(type) {
    case CREATE_PASSWORD_REQUEST: {
      return {
        ...state,
        user: {},
        status: 'running',
      };
    }

    case CREATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        status: 'idle',
      };
    }

    case CREATE_PASSWORD_FAILURE: {
      return {
        ...state,
        user: {},
        isAuthenticated: false,
        status: 'idle',
      };
    }

    case LOGIN_REQUEST: {
      return {
        ...state,
        user: {},
        status: 'running',
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        status: 'idle',
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        user: payload.user,
        isAuthenticated: false,
        status: 'idle',
      };
    }

    case SIGNUP_REQUEST: {
      return {
        ...state,
        user: {},
        signUpState: 0,
        status: 'running',
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        user: payload.user,
        signUpState: payload.state,
        status: 'idle',
      };
    }

    case SIGNUP_FAILURE: {
      return {
        ...state,
        user: {},
        signUpState: 0,
        status: 'idle',
      };
    }

    case LOGOUT_REQUEST:
      return {
        ...state,
        status: 'running',
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: {
          isAdmin: false,
        },
        status: 'idle',
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        status: 'idle',
      };

    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        status: 'running',
      };

    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        status: 'idle',
        user: payload.data,
      };

    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        user: {},
        isAuthenticated: false,
        status: 'idle',
      };

    case UPDATE_PERSONAL_INFO_REQUEST: {
      return {
        ...state,
        status: 'running',
      };
    }

    case UPDATE_PERSONAL_INFO_SUCCESS: {
      const cloneState = _.cloneDeep(state);
      _.set(cloneState, 'user', payload);
      _.set(cloneState, 'status', 'idle');
      return cloneState;
    }

    case UPDATE_PERSONAL_INFO_FAILURE: {
      return {
        ...state,
        status: 'idle',
      };
    }

    default: {
      return state;
    }
  }
}
