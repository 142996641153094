import React from 'react';
import YieldsTable from '../../components/Dashboard/YieldsTable';

class Yields extends React.PureComponent {
  render() {
    return (
      <YieldsTable />
    );
  }
}

export default Yields;
