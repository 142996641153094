/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Form, Alert, Checkbox, Upload, message, DatePicker } from 'antd';
//import { ApiService } from '../../services';

const Yields = () => {
  const [form] = Form.useForm();

  const [formState, setFormState ] = useState({
    message: '',
    type: false,
    visible: false,
    fileList: [],
    uploading: false,
  });

  useEffect(()=> {
    return [];
  }, [formState]);

  const payYields = () => {
    setFormState({uploading: true});
    form.validateFields().then((values) => {
      const formData = new FormData();
      formData.append("amount", values.amount);
      formData.append("date", values.date);
      formData.append("file", values.file[0].originFileObj);
        fetch(process.env.REACT_APP_API_ENDPOINT + '/api/admin/yields/payYields', {
            method: 'POST',
            body: formData,
            credentials: 'include',
        }).then((response) => response.json())
        .then((result) => {
          form.resetFields();
          setFormState({message: result.message, type: result.success ? 'success': 'error', visible: true, uploading: false});
        }).catch((result) => {
          setFormState({message: result.message, type: result.success ? 'success': 'error', visible: true, uploading: false});
        });
      }).catch((error) => {
          console.log(error);
      });
    
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    //setFormState({message: errorInfo, type: 'error', visible: true});
    setFormState({message: errorInfo, type: errorInfo.success ? 'success': 'error', visible: true});

  };

  const beforeUpload = (file) => {
    setFormState((state) =>(
      {fileList: [...state.fileList, file]}));
    return false;
  };
  
  const onFileChange = (info) => {
    if(info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if(info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if(info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const getFile = (e) => {
    console.log('Upload event:', e);
    
    if(Array.isArray(e)) {
      return e;
    }
   return e?.fileList;
  };

  return (
    <div className="user-details-block-wrapper">
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={payYields}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
    >
    <Form.Item
      label="Revenue Amount"
      name="amount"
      rules={[
        {
          required: true,
          message: 'Please input the amount!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if(!value || getFieldValue('amount') >= 0) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Please enter an amount.'));
          },
        }),
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Date"
      name="date"
      rules={[
        {
          required: true,
          message: 'Please include the date!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if(!value || getFieldValue('date') !== '') {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Please include the  date!'));
          },
        }),
      ]}
    >
      <DatePicker />
    </Form.Item>

    <Form.Item
      label="File"
      name="file"
      valuePropName="fileList"
      getValueFromEvent={getFile}
      rules={[
        {
          required: true,
          message: 'Please include the yield report!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if(!value || getFieldValue('file') !== '') {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Please include the yield report!'));
          },
        }),
      ]}
    >
     <Upload
        onChange={onFileChange}
        accept=".pdf"

        beforeUpload={beforeUpload}
        /*customRequest={()=>{ 
          setTimeout(() => { return}, 0);
        }}*/
        withCredentials={true}
       >
          <Button 
          loading={formState.uploading} 
          icon={<UploadOutlined />}
          >
            Click to Upload
          </Button>
        </Upload>
    </Form.Item>

    <Form.Item 
      name="confirm" 
      wrapperCol={{ offset: 8, span: 16 }}
      valuePropName="checked"
      rules={[
        {
         validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Please confirm by checking the box.')),
        },
      ]}
    >
        <Checkbox>Confirm</Checkbox>
      </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        PAY!
      </Button>
    </Form.Item>
  </Form>

    {formState.visible && (<div>
      <Alert message={formState.message} type={formState.type} />
    </div>
    )}
  </div>
  );
};

export default Yields;
