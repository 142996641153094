import { createAction } from 'redux-actions';
import { createPromiseAction } from '../utils';

/**
 * Action Types
 */

export const GET_USERS_REQUEST = '@users/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = '@users/GET_USERS_FAILURE';

export const GET_PENDING_USERS_REQUEST = '@admin/GET_PENDING_USERS_REQUEST';
export const GET_PENDING_USERS_SUCCESS = '@admin/GET_PENDING_USERS_SUCCESS';
export const GET_PENDING_USERS_FAILURE = '@admin/GET_PENDING_USERS_FAILURE';

export const APPROVE_PENDING_USER_REQUEST = '@admin/APPROVE_PENDING_USER_REQUEST';
export const APPROVE_PENDING_USER_SUCCESS = '@admin/APPROVE_PENDING_USER_SUCCESS';
export const APPROVE_PENDING_USER_FAILURE = '@admin/APPROVE_PENDING_USER_FAILURE';

export const GET_BLACKLIST_USERS_REQUEST = '@admin/GET_BLACKLIST_USERS_REQUEST';
export const GET_BLACKLIST_USERS_SUCCESS = '@admin/GET_BLACKLIST_USERS_SUCCESS';
export const GET_BLACKLIST_USERS_FAILURE = '@admin/GET_BLACKLIST_USERS_FAILURE';

export const BLACKLIST_USER_REQUEST = '@admin/BLACKLIST_USER_REQUEST';
export const BLACKLIST_USER_SUCCESS = '@admin/BLACKLIST_USER_SUCCESS';
export const BLACKLIST_USER_FAILURE = '@admin/BLACKLIST_USER_FAILURE';

export const UNBLACKLIST_USER_REQUEST = '@admin/UNBLACKLIST_USER_REQUEST';
export const UNBLACKLIST_USER_SUCCESS = '@admin/UNBLACKLIST_USER_SUCCESS';
export const UNBLACKLIST_USER_FAILURE = '@admin/UNBLACKLIST_USER_FAILURE';

export const GET_USER_LOGINS_REQUEST = '@admin/GET_USER_LOGINS_REQUEST';
export const GET_USER_LOGINS_SUCCESS = '@admin/GET_USER_LOGINS_SUCCESS';
export const GET_USER_LOGINS_FAILURE = '@admin/GET_USER_LOGINS_FAILURE';

export const GENERATE_CODE_REQUEST = '@admin/GENERATE_CODE_REQUEST';
export const GENERATE_CODE_SUCCESS = '@admin/GENERATE_CODE_SUCCESS';
export const GENERATE_CODE_FAILURE = '@admin/GENERATE_CODE_FAILURE';

export const GET_REGISTRATION_CODE_REQUEST = '@admin/GET_REGISTRATION_CODE_REQUEST';
export const GET_REGISTRATION_CODE_SUCCESS = '@admin/GET_REGISTRATION_CODE_SUCCESS';
export const GET_REGISTRATION_CODE_FAILURE = '@admin/GET_REGISTRATION_CODE_FAILURE';

export const GET_USER_BY_ID_REQUEST = '@users/GET_USER_BY_ID_REQUEST';
export const GET_USER_BY_ID_SUCCESS = '@users/GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILURE = '@users/GET_USER_BY_ID_FAILURE';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const GET_USER_ORDERS_REQUEST = 'GET_USER_ORDERS_REQUEST';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDERS_FAILURE';

export const GET_ALL_ORDERS_REQUEST = '@orders/GET_ALL_ORDERS_REQUEST';
export const GET_ALL_ORDERS_SUCCESS = '@orders/GET_ALL_ORDERS_SUCCESS';
export const GET_ALL_ORDERS_FAILURE = '@orders/GET_ALL_ORDERS_FAILURE';

export const UPDATE_USER_ORDER_STATUS_REQUEST = 'UPDATE_USER_ORDER_STATUS_REQUEST';
export const UPDATE_USER_ORDER_STATUS_SUCCESS = 'UPDATE_USER_ORDER_STATUS_SUCCESS';
export const UPDATE_USER_ORDER_STATUS_FAILURE = 'UPDATE_USER_ORDER_STATUS_FAILURE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';

export const RESET_PHOTO = 'RESET_PHOTO';

export const CREATE_NEWS_REQUEST = 'CREATE_NEWS_REQUEST';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAILURE = 'CREATE_NEWS_FAILURE';

export const DELETE_NEWS_REQUEST = 'DELETE_NEWS_REQUEST';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE';

export const GET_NEWS_BY_ID_REQUEST = 'GET_NEWS_BY_ID_REQUEST';
export const GET_NEWS_BY_ID_SUCCESS = 'GET_NEWS_BY_ID_SUCCESS';
export const GET_NEWS_BY_ID_FAILURE = 'GET_NEWS_BY_ID_FAILURE';

export const UPDATE_NEWS_REQUEST = 'UPDATE_NEWS_REQUEST';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAILURE = 'UPDATE_NEWS_FAILURE';

export const RESET_NEWS = 'RESET_NEWS';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_DATE = 'CHANGE_DATE';
export const CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION';
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const GET_ORDERS_BY_USER_ID_REQUEST = 'GET_ORDERS_BY_USER_ID_REQUEST';
export const GET_ORDERS_BY_USER_ID_SUCCESS = 'GET_ORDERS_BY_USER_ID_SUCCESS';
export const GET_ORDERS_BY_USER_ID_FAILURE = 'GET_ORDERS_BY_USER_ID_FAILURE';

export const GET_ORDERS_SUMMARY_REQUEST = 'GET_ORDERS_SUMMARY_REQUEST';
export const GET_ORDERS_SUMMARY_SUCCESS = 'GET_ORDERS_SUMMARY_SUCCESS';
export const GET_ORDERS_SUMMARY_FAILURE = 'GET_ORDERS_SUMMARY_FAILURE';

export const GET_INVESTMENT_ROUND_REQUEST = 'GET_INVESTMENT_ROUND_REQUEST';
export const GET_INVESTMENT_ROUND_SUCCESS = 'GET_INVESTMENT_ROUND_SUCCESS';
export const GET_INVESTMENT_ROUND_FAILURE = 'GET_INVESTMENT_ROUND_FAILURE';

/**
 * Action Creators
 */
export const adminActions = {
  usersRequest: createPromiseAction(GET_USERS_REQUEST),
  usersSuccess: createAction(GET_USERS_SUCCESS),
  usersFailure: createAction(GET_USERS_FAILURE),

  getPendingUsersRequest: createPromiseAction(GET_PENDING_USERS_REQUEST),
  getPendingUsersSuccess: createAction(GET_PENDING_USERS_SUCCESS),
  getPendingUsersFailure: createAction(GET_PENDING_USERS_FAILURE),

  approvePendingUserRequest: createPromiseAction(APPROVE_PENDING_USER_REQUEST),
  approvePendingUserSuccess: createPromiseAction(APPROVE_PENDING_USER_SUCCESS),
  approvePendingUserFailure: createPromiseAction(APPROVE_PENDING_USER_FAILURE),

  getBlacklistUsersRequest: createPromiseAction(GET_BLACKLIST_USERS_REQUEST),
  getBlacklistUsersSuccess: createAction(GET_BLACKLIST_USERS_SUCCESS),
  getBlacklistUsersFailure: createAction(GET_BLACKLIST_USERS_FAILURE),

  blacklistUserRequest: createPromiseAction(BLACKLIST_USER_REQUEST),
  blacklistUserSuccess: createPromiseAction(BLACKLIST_USER_SUCCESS),
  blacklistUserFailure: createPromiseAction(BLACKLIST_USER_FAILURE),

  unblacklistUserRequest: createPromiseAction(UNBLACKLIST_USER_REQUEST),
  unblacklistUserSuccess: createPromiseAction(UNBLACKLIST_USER_SUCCESS),
  unblacklistUserFailure: createPromiseAction(UNBLACKLIST_USER_FAILURE),

  userLoginsRequest: createPromiseAction(GET_USER_LOGINS_REQUEST),
  userLoginsSuccess: createAction(GET_USER_LOGINS_SUCCESS),
  userLoginsFailure: createAction(GET_USER_LOGINS_FAILURE),

  getRegistrationCodeRequest: createPromiseAction(GET_REGISTRATION_CODE_REQUEST),
  getRegistrationCodeSuccess: createAction(GET_REGISTRATION_CODE_SUCCESS),
  getRegistrationCodeFailure: createAction(GET_REGISTRATION_CODE_FAILURE),

  generateCodeRequest: createPromiseAction(GENERATE_CODE_REQUEST),
  generateCodeSuccess: createAction(GENERATE_CODE_SUCCESS),
  generateCodeFailure: createAction(GENERATE_CODE_FAILURE),

  ordersRequest: createPromiseAction(GET_ALL_ORDERS_REQUEST),
  ordersSuccess: createAction(GET_ALL_ORDERS_SUCCESS),
  ordersFailure: createAction(GET_ALL_ORDERS_FAILURE),

  getUserOrdersRequest: createPromiseAction(GET_USER_ORDERS_REQUEST),
  getUserOrdersSuccess: createAction(GET_USER_ORDERS_SUCCESS),
  getUserOrdersFailure: createAction(GET_USER_ORDERS_FAILURE),

  createOrderRequest: createPromiseAction(CREATE_ORDER_REQUEST),
  createOrderSuccess: createAction(CREATE_ORDER_SUCCESS),
  createOrderFailure: createAction(CREATE_ORDER_FAILURE),

  updateUserOrdersRequest: createPromiseAction(UPDATE_USER_ORDER_STATUS_REQUEST),
  updateUserOrdersSuccess: createAction(UPDATE_USER_ORDER_STATUS_SUCCESS),
  updateUserOrdersFailure: createAction(UPDATE_USER_ORDER_STATUS_FAILURE),

  deleteOrderRequest: createPromiseAction(DELETE_ORDER_REQUEST),
  deleteOrderSuccess: createAction(DELETE_ORDER_SUCCESS),
  deleteOrderFailure: createAction(DELETE_ORDER_FAILURE),

  updateOrderRequest: createPromiseAction(UPDATE_ORDER_REQUEST),
  updateOrderSuccess: createAction(UPDATE_ORDER_SUCCESS),
  updateOrderFailure: createAction(UPDATE_ORDER_FAILURE),

  uploadDocumentRequest: createPromiseAction(UPLOAD_DOCUMENT_REQUEST),
  uploadDocumentSuccess: createAction(UPLOAD_DOCUMENT_SUCCESS),
  uploadDocumentFailure: createAction(UPLOAD_DOCUMENT_FAILURE),

  getDocumentRequest: createPromiseAction(GET_DOCUMENT_REQUEST),
  getDocumentSuccess: createAction(GET_DOCUMENT_SUCCESS),
  getDocumentFailure: createAction(GET_DOCUMENT_FAILURE),

  deleteDocumentRequest: createPromiseAction(DELETE_DOCUMENT_REQUEST),
  deleteDocumentSuccess: createAction(DELETE_DOCUMENT_SUCCESS),
  deleteDocumentFailure: createAction(DELETE_DOCUMENT_FAILURE),

  getNewsRequest: createPromiseAction(GET_NEWS_REQUEST),
  getNewsSuccess: createAction(GET_NEWS_SUCCESS),
  getNewsFailure: createAction(GET_NEWS_FAILURE),

  uploadPhotoRequest: createPromiseAction(UPLOAD_PHOTO_REQUEST),
  uploadPhotoSuccess: createAction(UPLOAD_PHOTO_SUCCESS),
  uploadPhotoFailure: createAction(UPLOAD_PHOTO_FAILURE),

  resetPhoto: createAction(RESET_PHOTO),

  createNewsRequest: createPromiseAction(CREATE_NEWS_REQUEST),
  createNewsSuccess: createAction(CREATE_NEWS_SUCCESS),
  createNewsFailure: createAction(CREATE_NEWS_FAILURE),

  deleteNewsRequest: createPromiseAction(DELETE_NEWS_REQUEST),
  deleteNewsSuccess: createAction(DELETE_NEWS_SUCCESS),
  deleteNewsFailure: createAction(DELETE_NEWS_FAILURE),

  getNewsByIdRequest: createPromiseAction(GET_NEWS_BY_ID_REQUEST),
  getNewsByIdSuccess: createAction(GET_NEWS_BY_ID_SUCCESS),
  getNewsByIdFailure: createAction(GET_NEWS_BY_ID_FAILURE),

  updateNewsRequest: createPromiseAction(UPDATE_NEWS_REQUEST),
  updateNewsSuccess: createAction(UPDATE_NEWS_SUCCESS),
  updateNewsFailure: createAction(UPDATE_NEWS_FAILURE),

  resetNews: createAction(RESET_NEWS),
  changeName: createAction(CHANGE_NAME),
  changeDate: createAction(CHANGE_DATE),
  changeDescription: createAction(CHANGE_DESCRIPTION),

  updateUserInfoRequest: createPromiseAction(UPDATE_USER_INFO_REQUEST),
  updateUserInfoSuccess: createAction(UPDATE_USER_INFO_SUCCESS),
  updateUserInfoFailure: createAction(UPDATE_USER_INFO_FAILURE),

  getUserByIdRequest: createAction(GET_USER_BY_ID_REQUEST),
  getUserByIdSuccess: createAction(GET_USER_BY_ID_SUCCESS),
  getUserByIdFailure: createAction(GET_USER_BY_ID_FAILURE),

  getOrdersByUserIdRequest: createAction(GET_ORDERS_BY_USER_ID_REQUEST),
  getOrdersByUserIdSuccess: createAction(GET_ORDERS_BY_USER_ID_SUCCESS),
  getOrdersByUserIdFailure: createAction(GET_ORDERS_BY_USER_ID_FAILURE),

  getOrdersSummaryRequest: createAction(GET_ORDERS_SUMMARY_REQUEST),
  getOrdersSummarySuccess: createAction(GET_ORDERS_SUMMARY_SUCCESS),
  getOrdersSummaryFailure: createAction(GET_ORDERS_SUMMARY_FAILURE),

  getInvestmentRoundRequest: createAction(GET_INVESTMENT_ROUND_REQUEST),
  getInvestmentRoundSuccess: createAction(GET_INVESTMENT_ROUND_SUCCESS),
  getInvestmentRoundFailure: createAction(GET_INVESTMENT_ROUND_FAILURE),
};
