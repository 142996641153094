import React from 'react';

class InvestorUpdates extends React.PureComponent {
  render() {
    return (
      <div className="overview">
        <div className="notification">
          <p>
            View the latest TurnCoin updates <a href="https://www.turncoin.com/investorupdates" target="_blank" rel="noreferrer">here</a>.
            <br></br><br></br> Password: <b>Turncoin007</b> 
          </p>
        </div>
      </div>
    );
  }
}

export default InvestorUpdates;
