import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { orderActions, authActions } from '../../core';

import icMenuBar from '../../assets/img/menu_bar.png';
import TxtFormat from '../../components/TxtFormat/TxtFormat';

class DefaultHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false,
      headerHeight: 0,
    };
  }

  componentDidMount() {
    const headerHeight = document.getElementById('header').clientHeight;
    this.setState({ headerHeight }, this.props.getUserInfo);
  }

  toggleMenu = () => {
    if(this.state.isShowMenu) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  };

  showMenu = () => {
    this.setState({ isShowMenu: true });
  };

  hideMenu = () => {
    this.setState({ isShowMenu: false });
  };

  navigateToHome = () => {
    this.props.history.push('/');
  };

  handleClickBuyButton = () => {
    this.props.createBuyOrder();
  };

  render () {
    const { isAuthenticated, orderStatus, user } = this.props;
    const { headerHeight } = this.state;
    return (
      <div id="header" className="header" style={{ backgroundColor: 'white' }}>
        <img className="menu_bar" src={icMenuBar} alt="" onClick={this.toggleMenu} />
        <NavLink className="turn_coin_logo_wrapper" key="" to="/">
          <h1 className="logo">TURN<p>COIN.</p></h1>
        </NavLink>
        <div className="nav_items">
          {user.isAdmin && <NavLink key="backend" to="/backend" activeClassName="selected"><span>BACKEND</span></NavLink>}
          <NavLink key="account" to="/dashboard" activeClassName="selected">{ TxtFormat('nav.menu.account.title') }</NavLink>
          {!isAuthenticated ? (
            <NavLink key="login" to="/login">{ TxtFormat('nav.menu.login.title') }</NavLink>
          ) : (
            <NavLink key="logout" to="/logout">{ TxtFormat('nav.menu.logout.title') }</NavLink>
          )}
          <Link to="/purchase" style={{ marginRight: '0px' }}>
            <Button loading={orderStatus !== 'idle'}>
              {TxtFormat('main.buy.turncoin.btn.title')}
            </Button>
          </Link>
        </div>
        <div className={`mobile_menu center ${this.state.isShowMenu ? 'show' : ''}`} style={{ top: headerHeight }}>
          <div className="mobile_menu_wrapper">
            <CloseOutlined type="close" onClick={this.hideMenu} />
            <ul>
              {user.isAdmin && <li><NavLink key="backend" to="/backend" activeClassName="selected">BACKEND</NavLink></li>}
              <li><NavLink key="account" to="/dashboard" activeClassName="selected">{ TxtFormat('nav.menu.account.title') }</NavLink></li>
              <li>
                {!isAuthenticated ? (
                  <NavLink key="login" to="/login">{ TxtFormat('nav.menu.login.title') }</NavLink>
                ) : (
                  <NavLink key="logout" to="/logout">{ TxtFormat('nav.menu.logout.title') }</NavLink>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

DefaultHeader.propTypes = {
  createBuyOrder: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
  orderStatus: PropTypes.string,
  user: PropTypes.object,
  getUserInfo: PropTypes.func.isRequired,
};

DefaultHeader.defaultProps = {
  isAuthenticated: false,
  history: {},
  orderStatus: 'idle',
  user: {},
};

const mapStateToProps = (state) => ({
  orderStatus: state.order.status,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  createBuyOrder: (payload) => dispatch(orderActions.createBuyOrderRequest(payload)),
  getUserInfo: () => dispatch(authActions.getUserInfoRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
