import React, { PureComponent } from 'react';
import DefaultHeader from '../DefaultLayout/DefaultHeader';
import DefaultFooter from '../DefaultLayout/DefaultFooter';
import CoinDescription from './CoinDescription';

class Main extends PureComponent {
  render () {
    return (
      <div className="main_block">
        <DefaultHeader />
        <CoinDescription />
        <DefaultFooter />
      </div>
    );
  }
}

export default Main;
