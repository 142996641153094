import React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { columnsForUserOrders } from './columns';

class BackendTableOrders extends React.Component {
  constructor(props) {
    super(props);
    window.onresize = this.changeTableType;
    this.state = {
      isMobile: window.innerWidth < 768,
    };
  }

  changeTableType = () => {
    if(window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  };

  render() {
    const { orders, onSelectOrder } = this.props;
    return (
      <Table
        columns={columnsForUserOrders(orders, onSelectOrder)}
        dataSource={orders}
        bordered
        rowKey="id"
        pagination={false}
        loading={this.props.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
      />
    );
  }
}

BackendTableOrders.propTypes = {
  status: PropTypes.string,
  hiddenEmailField: PropTypes.bool,
  orders: PropTypes.array,
  onSelectOrder: PropTypes.func.isRequired,
};

BackendTableOrders.defaultProps = {
  status: 'idle',
  hiddenEmailField: false,
  orders: [],
};

const mapStateToProps = ({ admin }) => ({
  status: admin.status,
});

export default connect(mapStateToProps)(BackendTableOrders);
