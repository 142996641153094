export { promisify } from './promisify';
export { numberFormatter } from './numberFormatter';
export { sanitizeHTML, excerptString } from './stringFormatter';
export function calculateQueryStr(params) {
  return Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');
}

export function getFieldsData(values, errors) {
  let fields = {};

  Object.keys(errors).forEach((key) => {
    fields = {
      ...fields,
      [key]: {
        value: values[key],
        errors: [new Error(errors[key])],
      },
    };
  });

  return fields;
}
