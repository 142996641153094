import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { unregister } from './registerServiceWorker';
// import registerServiceWorker from './registerServiceWorker';

import 'font-awesome/css/font-awesome.min.css';
import 'antd/dist/antd.css';
import './assets/styles/index.css';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

unregister();
// registerServiceWorker();
