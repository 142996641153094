import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { adminActions } from '../../core';
import BackendTableUsers from '../../components/Backend/BackendTableUsers';
import BackendPendingUsers from '../../components/Backend/BackendPendingUsers';
import BackendBlacklistUsers from '../../components/Backend/BackendBlacklistUsers';
const { TabPane } = Tabs;

function BackendUsers(props) {
  const [tabId, setTabId] = useState('');
  const { users, pendingUsers, blacklistUsers, status, getUsersRequest, getPendingUsers, approvePendingUser, getBlacklistUsers, blacklistUser, unblacklistUser } = props;

  const handleChangeTab = (key) => {
    setTabId(key);
  };

  return (
    <Tabs onChange={handleChangeTab}>
      <TabPane tab="Users" key="users">
        <BackendTableUsers
          data={users}
          status={status}
          tabId={tabId}
          getUsersRequest={getUsersRequest}
          blacklistUser={blacklistUser}
          unblacklistUser={unblacklistUser}
          pagination={false}
        />
      </TabPane>
      <TabPane tab="Pending Users" key="pending-users">
        <BackendPendingUsers
          data={pendingUsers}
          status={status}
          tabId={tabId}
          getPendingUsers={getPendingUsers}
          approvePendingUser={approvePendingUser}
          blacklistUser={blacklistUser}
          pagination={false}
        />
      </TabPane>
      <TabPane tab="Blacklist" key="blacklist-users">
        <BackendBlacklistUsers
          data={blacklistUsers}
          status={status}
          tabId={tabId}
          getBlacklistUsers={getBlacklistUsers}
          unblacklistUser={unblacklistUser}
          pagination={false}
        />
      </TabPane>
    </Tabs>
  );
}

BackendUsers.propTypes = {
  users: PropTypes.shape({
    users: PropTypes.array,
  }).isRequired,
  pendingUsers: PropTypes.shape({
    users: PropTypes.array,
  }).isRequired,
  blacklistUsers: PropTypes.shape({
    users: PropTypes.array,
  }).isRequired,
  getUsersRequest: PropTypes.func.isRequired,
  getPendingUsers: PropTypes.func.isRequired,
  approvePendingUser: PropTypes.func.isRequired,
  getBlacklistUsers: PropTypes.func.isRequired,
  blacklistUser: PropTypes.func.isRequired,
  unblacklistUser: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = ({ admin }) => ({
  users: admin.users,
  pendingUsers: admin.pendingUsers,
  blacklistUsers: admin.blacklistUsers,
  status: admin.status,
});

const mapDispatchToProps = {
  getUsersRequest: adminActions.usersRequest,
  getPendingUsers: adminActions.getPendingUsersRequest,
  approvePendingUser: adminActions.approvePendingUserRequest,
  blacklistUser: adminActions.blacklistUserRequest,
  unblacklistUser: adminActions.unblacklistUserRequest,
  getBlacklistUsers: adminActions.getBlacklistUsersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackendUsers);
