import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../core';

class Logout extends Component {
  componentDidMount() {
    this.props.logoutRequest(
      () => {
        this.props.history.push('/');
      },
      () => {
        this.props.history.push('/');
      },
    );
  }

  render() {
    return (
      <div style={{ fontSize: 24, marginTop: 10 }}>
        &nbsp;&nbsp;Please wait...
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logoutRequest: () => dispatch(authActions.logoutRequest()),
});

export default connect(null, mapDispatchToProps)(Logout);
