import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <div style={{ fontSize: 60 }}>
      404 Error<br />
      Page Not Found<br />
      This page is not implemented yet. <Link to="/">Go Home</Link>
    </div>
  );
}

export default PageNotFound;
