import React from 'react';
import Yields from '../../components/Backend/Yields';

class BackendYields extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {

    return (
      <>
       <Yields />
      </>
    );
  }
}

export default BackendYields;
