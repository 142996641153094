import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import TxtFormat from '../../components/TxtFormat/TxtFormat';
import { numberFormatter } from '../../utilities';

import hamburguerMenuImg from '../../assets/img/hamburger_menu.svg';

class DashboardHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenu: false,
      headerHeight: 0,
    };
  }

  componentDidMount() {
    const headerHeight = document.getElementById('header').clientHeight;
    this.setState({ headerHeight });
  }

  showMenu = () => {
    this.setState({ isShowMenu: true });
  };

  hideMenu = () => {
    this.setState({ isShowMenu: false });
  };

  toggleMenu = () => {
    if(this.state.isShowMenu) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  };

  render() {
    const { holdings, user } = this.props;
    const { headerHeight } = this.state;
    return (
      <div id="header" className="header dashboard-header">

          <div className="logo-container">
            <Link to="/">
              <h1 className="logo" style={{fontFamily: 'rajdhani'}}>TURN<p style={{fontFamily: 'rajdhani'}}>COIN</p></h1>
            </Link>
          </div>
          <div className="centered">
            <img alt="" className="drivecoin" src="/images/X---Coin.png" />
            <span>
              <span className="desktop">Your </span>
              <span className="subcolor">Turn</span>
              <span className="desktop">Coin Holdings: </span>
              <span className="subcolor">{numberFormatter(holdings, 0)}</span>
            </span>
          </div>
          <div className="right-aligned">
            <img className="menu_bar" src={hamburguerMenuImg} alt="" onClick={this.toggleMenu} />
            <div className={`mobile_menu center ${this.state.isShowMenu ? 'show' : ''}`} style={{ top: headerHeight }}>
              <div className="mobile_menu_wrapper">
                <CloseOutlined type="close" onClick={this.hideMenu} />
                <ul>
                  <li><NavLink exact key="home" to="/" activeClassName="selected">{ TxtFormat('nav.menu.home.title') }</NavLink></li>
                  <li><NavLink key="account" to="/dashboard" activeClassName="selected">{ TxtFormat('nav.menu.account.title') }</NavLink></li>
                  {
                    user.isAdmin && <li><NavLink key="backend" to="/backend" activeClassName="selected"><span>BACKEND</span></NavLink></li>
                  }
                <li><NavLink key="logout" to="/logout">{ TxtFormat('nav.menu.logout.title') }</NavLink></li>
              </ul>
            </div>
          </div>
          <NavLink to="/dashboard" className="user_account">
            <span>
              <span>{user.firstName}</span>
              {
                  user.lastName
                  && <span> {user.lastName[0]}.</span>
                }
            </span>
          </NavLink>
        </div>
      </div>
    );
  }
}

DashboardHeader.propTypes = {
  user: PropTypes.object,
  holdings: PropTypes.number,
};

DashboardHeader.defaultProps = {
  user: {
    firstName: '',
    lastName: '',
  },
  holdings: 0,
};

export default DashboardHeader;
