import React from 'react';
import { Tabs, Form, Input, Button, Spin, Descriptions } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TableOrders from '../../components/Backend/TableOrders';
import { numberFormatter } from '../../utilities';
import OrderModal from '../../components/Backend/OrderModal';
import { adminActions } from '../../core';

const { TabPane } = Tabs;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* const renderTradableTitle = (isLocked, status) => function(orders) {
  const totalDollars = orders.filter((order) => Boolean(order.isLocked) === isLocked).reduce((sum, order) => sum + order.total, 0);
  const totalTC = orders.filter((order) => Boolean(order.isLocked) === isLocked).reduce((sum, order) => sum + order.amount, 0);
  const colorMapping = {
    RESERVED: '#e63523',
    ISSUED: '#5aad5b',
    PENDING: '#2c70ba',
  };
  const statusMapping = {
    RESERVED: 'Reserved',
    ISSUED: 'Issued',
    PENDING: 'Pending',
  };

  return (
    <div style={{ fontSize: 18, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', color: colorMapping[status] }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!isLocked
          ? <span style={{ fontWeight: 'bold' }}>Tradable TurnCoins</span>
          : <span style={{ fontWeight: 'bold' }}>Non-Tradable TurnCoins</span>}

        {status
          && <span style={{ fontWeight: 'bold' }}>{ statusMapping[status] }</span>}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div>
          <span style={{ minWidth: 110, display: 'inline-block' }}>Total Dollars:</span>
          <span style={{ minWidth: 120, display: 'inline-block', fontWeight: 'bold', textAlign: 'right' }}>{`${numberFormatter(totalDollars, 2)}`}</span>
        </div>

        <div>
          <span style={{ minWidth: 110, display: 'inline-block' }}>Total TurnCoin:</span>
          <span style={{ minWidth: 120, display: 'inline-block', fontWeight: 'bold', textAlign: 'right' }}>{numberFormatter(totalTC, 0)}</span>
        </div>

      </div>

    </div>
  );
}; */

// eslint-disable-next-line react/no-multi-comp
class BackendOrders extends React.Component {
  constructor(props) {
    super(props);
    this.scrollTimer = null;

    this.state = {
      firstName: '',
      lastName: '',
      entityName: '',
      email: '',
      editOrderVisible: false,
      order: {}
    };
  }

  componentDidMount() {
    const {getInvestmentRoundInfo, getSummary} = this.props;
    this.changePage(false)(0);
    this.changePage(true)(0);
    getSummary();
    getInvestmentRoundInfo();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.summary !== this.props.summary) {
      this.changePage(false)(0);
      this.changePage(true)(0);
    }
  }

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.changePage(false)(0);
    this.changePage(true)(0);
  };

  changePage = (isLocked) => (page) => {
    this.props.getOrders({
      page,
      limit: -1,
      email: this.state.email,
      isLocked,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      entityName: this.state.entityName,
    });
  };

  handleCancel = () => {
    this.setState({ editOrderVisible: false });
    this.props.getSummary();
  };

  setOrder = (order) => {
    this.setState({ editOrderVisible: true });
    if(order) this.setState({ order });
  };

  render() {
    const { userInfo } = this.props;
    const { tradableOrders, nonTradableOrders, summary } = this.props;
    const { user: userProfile } = userInfo;

    return (
      <>
        <div className="order-summary-section">
          <Spin spinning={tradableOrders.status !== 'idle' || nonTradableOrders.status !== 'idle'}>
            <Descriptions bordered title="Order Summary" column={1} className="order-summary">
              <Descriptions.Item label="TOTAL TURNCOIN MINTED">{numberFormatter(summary.totalTC, 0)}</Descriptions.Item>

              <Descriptions.Item className="tradable pending" label="Tradable TurnCoin - Pending">{numberFormatter(summary.tradablePendingTC, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable pending bought" label="Investment">{numberFormatter(summary.tradablePendingTCBought, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable pending given" label="Earned">{numberFormatter(summary.tradablePendingTCGiven, 0)}</Descriptions.Item>

              <Descriptions.Item className="tradable issued" label="Tradable TurnCoin - Issued">{numberFormatter(summary.tradableIssuedTC, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable issued bought" label="Investment">{numberFormatter(summary.tradableIssuedTCBought, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable issued given" label="Earned">{numberFormatter(summary.tradableIssuedTCGiven, 0)}</Descriptions.Item>

              <Descriptions.Item className="tradable reserved" label="Tradable TurnCoin - Reserved">{numberFormatter(summary.tradableReservedTC, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable reserved bought" label="Investment">{numberFormatter(summary.tradableReservedTCBought, 0)}</Descriptions.Item>
              <Descriptions.Item className="tradable reserved given" label="Earned">{numberFormatter(summary.tradableReservedTCGiven, 0)}</Descriptions.Item>

              <Descriptions.Item className="non-tradable pending" label="Non-Tradable TurnCoin - Pending">{numberFormatter(summary.nonTradablePendingTC, 0)}</Descriptions.Item>
              <Descriptions.Item className="non-tradable issued" label="Issued">{numberFormatter(summary.nonTradableIssuedTC, 0)}</Descriptions.Item>
              <Descriptions.Item className="non-tradable reserved" label="Reserved">{numberFormatter(summary.nonTradableReservedTC, 0)}</Descriptions.Item>
              <Descriptions.Item label="TurnCoin Issued">{numberFormatter(summary.issuedTC, 0)}</Descriptions.Item>
              <Descriptions.Item label="TURNCOIN UNISSUED">{numberFormatter(summary.unissuedTC, 0)}</Descriptions.Item>
            </Descriptions>
          </Spin>
        </div>

        <div className="search-form">
          <Form layout="inline" onFinish={this.handleSubmit}>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>First Name:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="firstName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Last Name:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="lastName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Entity:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="entityName"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontSize: 20, marginRight: 10 }}>Email:</span>}
              colon={false}
            >
              <Input
                type="text"
                name="email"
                className=""
                onChange={this.onFieldChange}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                className="btn-turncoin"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Investment" key="1">
            <TableOrders
              tradableOrders={tradableOrders}
              nonTradableOrders={nonTradableOrders}
              method="Investment"
              onSelectOrder={this.setOrder}
            />
          </TabPane>
          <TabPane tab="Earned" key="2">
            <TableOrders
              tradableOrders={tradableOrders}
              nonTradableOrders={nonTradableOrders}
              method="Earned"
              onSelectOrder={this.setOrder}
            />
          </TabPane>
        </Tabs>

        <OrderModal
          layout={layout}
          visible={this.state.editOrderVisible}
          onCancel={this.handleCancel}
          defaultValues={Object.assign(userProfile, this.state.order)}
          update
        />
      </>
    );
  }
}

BackendOrders.propTypes = {
  getSummary: PropTypes.func.isRequired,
  getOrders: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  tradableOrders: PropTypes.shape({
    ordersList: PropTypes.array,
    status: PropTypes.string,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      limit: PropTypes.number,
      total: PropTypes.number,
    }),
    state: PropTypes.string,
  }).isRequired,
  nonTradableOrders: PropTypes.shape({
    ordersList: PropTypes.array,
    status: PropTypes.string,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      limit: PropTypes.number,
      total: PropTypes.number,
    }),
    state: PropTypes.string,
  }).isRequired,
  summary: PropTypes.shape({
    totalTC: PropTypes.number,
    tradableTC: PropTypes.number,
    nonTradableTC: PropTypes.number,
    issuedTC: PropTypes.number,
    unissuedTC: PropTypes.number,
    tradablePendingTC: PropTypes.number,
    tradablePendingTCBought: PropTypes.number,
    tradablePendingTCGiven: PropTypes.number,
    tradableIssuedTC: PropTypes.number,
    tradableIssuedTCBought: PropTypes.number,
    tradableIssuedTCGiven: PropTypes.number,
    tradableReservedTC: PropTypes.number,
    tradableReservedTCBought: PropTypes.number,
    tradableReservedTCGiven: PropTypes.number,
    nonTradablePendingTC: PropTypes.number,
    nonTradableIssuedTC: PropTypes.number,
    nonTradableReservedTC: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  //investmentRounds: PropTypes.object,
  /*investmentRounds: PropTypes.shape({
    map: PropTypes.func,
  }),*/
  getInvestmentRoundInfo: PropTypes.func.isRequired,
};

BackendOrders.defaultProps = {
   match: {},
   tradableOrders: {},
};

const mapStateToProps = ({ admin }) => ({
  statusUpload: admin.statusUpload,
  status: admin.status,
  userInfo: admin.ordersByUserId,
  investmentRounds: admin.investmentRound,
  tradableOrders: admin.tradableOrders,
  nonTradableOrders: admin.nonTradableOrders,
  summary: admin.summary,
});

const mapDispatchToProps = (dispatch) => ({
  getSummary: (payload, resolve, reject) => dispatch(adminActions.getOrdersSummaryRequest(payload, resolve, reject)),
  getOrders: (payload, resolve, reject) => dispatch(adminActions.ordersRequest(payload, resolve, reject)),
  deleteOrder: (payload, resolve, reject) => dispatch(adminActions.deleteOrderRequest(payload, resolve, reject)),
  updateOrder: (payload, resolve, reject) => dispatch(adminActions.updateOrderRequest(payload, resolve, reject)),
  getInvestmentRoundInfo: () => dispatch(adminActions.getInvestmentRoundRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackendOrders);
