import { initialState } from '../initialState';
import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,

  CREATE_BUY_ORDER_REQUEST,
  CREATE_BUY_ORDER_SUCCESS,
  CREATE_BUY_ORDER_FAILURE,

  GET_ORDERS_REQUEST,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,

  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,

  GET_EXCHANGE_RATE_REQUEST,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_FAILURE,
} from './actions';

export default function order(state = initialState.order, action = {}) {
  const { type, payload } = action;

  switch(type) {
    case CREATE_ORDER_REQUEST:
      return {
        ...state,
        order: {},
        orderStatus: 'running',
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: payload.order,
        orderStatus: 'idle',
      };
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        orderStatus: 'idle',
      };
    // create buy order
    case CREATE_BUY_ORDER_REQUEST:
      return {
        ...state,
        status: 'running',
      };
    case CREATE_BUY_ORDER_SUCCESS:
      return {
        ...state,
        status: 'idle',
      };
    case CREATE_BUY_ORDER_FAILURE:
      return {
        ...state,
        status: 'idle',
      };
    // get orders
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        status: 'running',
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload.orders,
        pagination: payload.pagination,
        status: 'idle',
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        status: 'idle',
      };
    // get analytics
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        status: 'running',
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics: payload,
        status: 'idle',
      };
    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        status: 'idle',
      };
    // get exchange rate
    case GET_EXCHANGE_RATE_REQUEST:
      return {
        ...state,
        status: 'running',
      };
    case GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        exchangeRate: payload,
        status: 'idle',
      };
    case GET_EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        status: 'idle',
      };
    default: {
      return state;
    }
  }
}
