import React, { PureComponent } from 'react';

class DescriptionCard extends PureComponent {
  render () {
    const { cardClassName, cardLogo, title, description } = this.props;
    return (
      <div className={`desc_item ${cardClassName}`}>
        <img src={cardLogo} alt="" />
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
    );
  }
}

export default DescriptionCard;
