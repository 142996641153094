import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';
import { Modal, Input, InputNumber, Form, Select, DatePicker, message } from 'antd';
import PropTypes from 'prop-types';
import { adminActions } from '../../core';

import { colors } from './SelectStatus';

class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.initalState = {
      validateStatus: '',
      dollarStatus: '',
      itemCostStatus: '',
      errorTotal: '',
      errorItemCost: '',
      errorAmount: '',
      roundOptions: [],
      showVestmentDate: false,
    };
    this.cloneState = _.cloneDeep(this.initalState);
    this.state = this.cloneState;
    this.orderRef = React.createRef();
  }

  componentDidMount(){
    const {getInvestmentRoundInfo} = this.props;
    getInvestmentRoundInfo();
  }

  buildOptions(){
    return this.props.investmentRounds.map((round) => {
      return (<Select.Option key={round.id} value={round.id}>{round.id}</Select.Option>);
    });
  }

  handleCancel = () => {
    this.orderRef.current.resetFields();
    this.props.onCancel();
  };

  handleDollarAmountChange = (value) => {
    if(typeof value !== 'number') return;
    // this.validateDollarAmount(value);

    const values = this.orderRef.current.getFieldsValue();
    if(value > 0 && parseFloat(values.itemCost) > 0) {
      this.orderRef.current.setFieldsValue({
        amount: Math.round(value / parseFloat(values.itemCost)),
      });
    }
  };

    handleCostChange = (value) => {
      if(typeof value !== 'number') return;
      const values = this.orderRef.current.getFieldsValue();

      
      if(value > 0 && parseFloat(values.total) >= 0) {
        this.orderRef.current.setFieldsValue({
          amount: Math.round(parseFloat(values.total) / value),
        });
      }
    };

    validateTurnCoinAmount = (value) => {
      const itemCost = this.orderRef.current.getFieldValue('itemCost');
      const total = this.orderRef.current.getFieldValue('total');

      if((total > 0 && Math.round(total / itemCost) !== Math.round(value))) {
        // callback('Turncoin Amount is not valid');
        this.setState({
          validateStatus: 'error',
          errorAmount: 'Turncoin Amount is not valid',
        });
        return;
      }
      this.setState(this.cloneState);
    };

    handleCreateOrder = () => {
      const { match, createOrder } = this.props;
      const { userId } = match.params;
      this.orderRef.current.validateFields().then((values) => {
        createOrder(
          { ...values, user: userId },
          () => { 
            this.orderRef.current.resetFields();
            message.success('The Order was successfully created');
            this.props.onCancel();
          },
          (errMsg) => { message.error(errMsg) },
        );
      });
    };

    handleUpdateOrder = () => {
      const {userId} = this.props.defaultValues;
  
      this.orderRef.current.validateFields().then((values) => {
        const params = {
          ...values,
          orderId: values.id,
          userId,
          orderTimestamp: values.orderTimestamp.format(),
        };
        if(values.status === 'DELETE') {
          this.props.deleteOrder(
            { id: values.id },
            () => {
              message.success('The Order was successfully deleted');
              this.props.getUserById({ userId });
              this.props.onCancel();
            },
            (errMsg) => { message.error(errMsg) },
          );
        } else {
          this.props.updateOrder(
            params,
            () => {
              message.success('The Order was successfully updated');
              this.props.getUserById({ userId });
              this.props.onCancel();
            },
            (errMsg) => { message.error(errMsg) },
          );
        }
      });
    };

    isLockChange = (value) => {
      const form = this.orderRef.current;
      if(value === 1) {
        form.setFieldsValue({ method: 'Investment' });
      }
    };

    handleFormValuesChange = (changedValues) => {
      const { defaultValues } = this.props;
      const formFieldName = Object.keys(changedValues)[0];
      if(formFieldName === "investmentRoundId") {
        //changedValues[formFieldName] == 'EARNED'  ? this.setState({showVestmentDate: true}) : this.setState({showVestmentDate: false});
        changedValues[formFieldName] = defaultValues.investmentRound.name;
        this.orderRef.current.setFieldsValue(changedValues[formFieldName]);
      }
    };

    /*checkFields = (values) => {
      console.log(values);
      const field = values[0].name[0];
      const value = values[0].value;
      console.log(field);

      if(field == 'method'){
        value == 'EARNED' ? this.setState({showVestmentDate: true}) : this.setState({showVestmentDate: false});
      }
    };*/

    render() {
      // eslint-disable-next-line react/prop-types
      const { visible,  initValues, defaultValues, update, layout } = this.props;
      let values = {};
      if(!update) {
        values = {
          firstName: initValues.firstName,
          lastName: initValues.lastName,
          entityName: initValues.entityName,
          total: 0,
          itemCost: 0,
          amount: 0,
        };
      }

      let modalProperties = {};
      if(!update) {
        modalProperties = {
          title: 'Create New Order',
          okText: 'Create',
          onOk: this.handleCreateOrder,
        };
      } else {
        modalProperties = {
          title: `Edit Order ${defaultValues.id}`,
          okText: 'Update',
          onOk: this.handleUpdateOrder,
        };
      }

      let formProperties = {};
      if(!update) {
        formProperties = {
          initialValues: values,
        };
      } else {
        
        /*if(defaultValues.method == 'EARNED'){
          this.setState({showVestmentDate: true});
        }*/
          
        const fields = [
          {
            name: ['id'],
            value: defaultValues.id,
          },
          {
            name: ['orderTimestamp'],
            value: moment(defaultValues.orderTimestamp),
          },
          {
            name: ['firstName'],
            value: defaultValues.firstName,
          },
          {
            name: ['lastName'],
            value: defaultValues.lastName,
          },
          {
            name: ['entityName'],
            value: defaultValues.entityName,
          },
          {
            name: ['investmentRoundId'],
            //title: defaultValues.investmentRound?.name,
            value: defaultValues.investmentRoundId,
          },
          {
            name: ['transactionType'],
            value: defaultValues.transactionType,
          },
          {
            name: ['total'],
            value: defaultValues.total,
          },
          {
            name: ['itemCost'],
            value: defaultValues.itemCost,
          },
          {
            name: ['amount'],
            value: defaultValues.amount,
          },
          {
            name: ['isLocked'],
            value: defaultValues.isLocked ? 1 : 0,
          },
          {
            name: ['status'],
            value: defaultValues.status,
          },
          {
            name: ['method'],
            value: defaultValues.method,
          },
          {
            name: ['vestmentDate'],
            value: moment(defaultValues.vestmentDate),
          },
        ];
        formProperties = {
          fields,
        };
      }

      return (
        <Modal
          visible={visible}
          onCancel={this.handleCancel}
          centered
          {... modalProperties}
        >
          <Form
            {...layout}
            ref={this.orderRef}
            {... formProperties}
            onFieldsChange={this.handleFormValuesChange}
          >
            {update && (
            <Form.Item
              name="id"
              label="Order #"
              rules={[{ required: true, message: 'Please input the order Id!' }]}
            >
              <Input disabled />
            </Form.Item>
            )}
            <Form.Item
              name="orderTimestamp"
              label="Order Date"
              rules={[{ required: true, message: 'Please select the order date!' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              name="firstName"
              label="First Name"
              //rules={[{ required: true, message: 'Please input the first name!' }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              //rules={[{ required: true, message: 'Please input the last name!' }]}
            >
              <Input disabled/>
            </Form.Item>

            {/*<Form.Item
              name="entityName"
              label="Entity Name"
            >
              <Input />
            </Form.Item>*/}

            <Form.Item
              name="investmentRoundId"
              label="Investment Round"
              rules={[{ required: true, message: 'Please select a value.' }]}
            >
              <Select>
                {this.buildOptions()}
              </Select>
            </Form.Item>

            <Form.Item
              name="transactionType"
              label="Transaction Type"
            >
              <Select>
                <Select.Option value="Private Equity">Private Equity</Select.Option>
                <Select.Option value="Legacy Group A">Legacy Group A</Select.Option>
                <Select.Option value="Legacy Group B">Legacy Group B</Select.Option>
                <Select.Option value="Legacy Group C">Legacy Group C</Select.Option>
                <Select.Option value="Mezzanine Group D">Mezzanine Group D</Select.Option>
                <Select.Option value="Holding Company">Holding Company</Select.Option>
                <Select.Option value="Founders">Founders</Select.Option>
                <Select.Option value="Charities">Charities</Select.Option>
                <Select.Option value="Team">Team</Select.Option>
                <Select.Option value="Consultants">Consultants</Select.Option>
                <Select.Option value="Partners">Partners</Select.Option>
                <Select.Option value="Promotors">Promotors</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="total"
              label="Dollar Amount"
              rules={[
                { required: true, message: 'Please input the dollar amount!' },
              ]}
              validateStatus={this.state.dollarStatus}
              help={this.state.errorTotal}
            >
              <InputNumber onChange={this.handleDollarAmountChange} min="0" />
            </Form.Item>

            <Form.Item
              name="itemCost"
              label="Price / TurnCoin"
              rules={[
                { required: true, message: 'Please input the turncoin price!' },
              ]}
              validateStatus={this.state.itemCostStatus}
              help={this.state.errorItemCost}
            >
              <InputNumber onChange={this.handleCostChange} min="0" />
            </Form.Item>

            <Form.Item
              name="amount"
              label="TurnCoin Amount"
              rules={[
                { required: true, message: 'Please input the turncoin amount!' },
              ]}
              validateStatus={this.state.validateStatus}
              help={this.state.errorAmount}
            >
              <InputNumber min="0" />
            </Form.Item>

            <Form.Item
              name="isLocked"
              label="Type"
            >
              <Select onChange={this.isLockChange}>
                <Select.Option value={0}>Tradable</Select.Option>
                <Select.Option value={1}>Non-Tradable</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="status"
              label="Status"
            >
              <Select>
                <Select.Option value="RESERVED" style={{ color: colors.reserved }}>RESERVED</Select.Option>
                <Select.Option value="PENDING" style={{ color: colors.pending }}>PENDING</Select.Option>
                <Select.Option value="ISSUED" style={{ color: colors.issued }}>ISSUED</Select.Option>
                <Select.Option value="CANCELLED">CANCELLED</Select.Option>
                <Select.Option value="DELETE">DELETE</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="method"
              label="Method"
              rules={[{ required: true, message: 'Please select a value.' }]}

            >
              <Select>
                <Select.Option value="Investment">INVESTMENT</Select.Option>
                <Select.Option value="Earned">EARNED</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="vestmentDate"
              label="Vestment Date"
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </Modal>
      );
    }
}

OrderModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  //onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    id: PropTypes.number,
    dateAdded: PropTypes.instanceOf(Date),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    entityName: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    orderTimestamp: PropTypes.string,
    total: PropTypes.number,
    itemCost: PropTypes.number,
    amount: PropTypes.number,
    isLocked: PropTypes.bool,
    status: PropTypes.string,
    method:PropTypes.string,
    turncoinHolding: PropTypes.number,
    investmentRound: PropTypes.shape({
      name: PropTypes.string,
    })
  }).isRequired,
  investmentRounds: PropTypes.arrayOf(
    PropTypes.shape({
      round: PropTypes.string
    }),
  ),
  getInvestmentRoundInfo: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  deleteOrder: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  match: PropTypes.object,
  initValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    entityName: PropTypes.string,
  }),
};

OrderModal.defaultProps = {
  defaultValues: {},
};


const mapStateToProps = ({ admin }) => ({
  investmentRounds: admin.investmentRound,
});

const mapDispatchToProps = {
  getInvestmentRoundInfo: adminActions.getInvestmentRoundRequest,
  createOrder: adminActions.createOrderRequest,
  updateOrder: adminActions.updateOrderRequest,
  deleteOrder: adminActions.deleteOrderRequest,
  getUserById: adminActions.getOrdersByUserIdRequest,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderModal));
