import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { store } from '../core';
import enMessages from '../language/en';
// import 'assets/styles/App.less';
import DefaultLayout from './DefaultLayout/DefaultLayout';

// addLocaleData([...en]);
const getMessages = (locale) => {
  switch(locale) {
    default:
      return enMessages;
  }
};

const initialLanguage = 'en';

function App() {
  return (
    <IntlProvider
      key={initialLanguage}
      locale={initialLanguage}
      messages={getMessages(initialLanguage)}
    >
      <BrowserRouter>
        <Provider store={store}>
          <DefaultLayout />
        </Provider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
