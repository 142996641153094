import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Divider } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { numberFormatter } from '../../utilities';
import { ApiService } from '../../services';

// const numberFormatter = str => (isNaN(Number(str)) ? str : Number(str).toLocaleString());

function CustomTitle({ title }) {
  return (
    <span style={{ fontSize: 18, color: '#07174A', fontWeight: 'bold', textAlign: 'center', display: 'inline-block', marginBottom: 20 }}>
      {title}
    </span>
  );
}

CustomTitle.propTypes = {
  title: PropTypes.string,
  
};
CustomTitle.defaultProps = {
};

// function PriceCell(text) {
//   return (
//     <span style={{ color: 'black' }}>
//       {
//       // Number(Number(text).toFixed(2)).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})
//       numberFormatter(text, 2)
//     }
//     </span>
//   );
// }

function TurncoinCell(text) {
  return (
    <span style={{ color: 'black' }}>
      {
      numberFormatter(text, 0)
    }
    </span>
  );
}

function YieldCell(text) {
  return (
    <span style={{ color: 'black' }}>
      ${numberFormatter(text, 2)}
    </span>
  );
}

function RevenueCell(text) {
  return (
    <span style={{ color: 'black' }}>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_API_ENDPOINT + '/api/' + text.fileUrl} download>{text.fileInfo?.filename}</a>
    </span>
  );
}

function YieldCellPerTC(text, record) {
  return (
    <span style={{ color: 'black' }}>
      ${numberFormatter(record.yield/record.turncoinAmount, 9)}
    </span>
  );
}


function DateCell(text) {
  return (
    <span style={{ color: 'black', textAlign: 'right' }}>
      {moment(text).format('MMM DD, YYYY')}
    </span>
  );
}

// const colors = { issued: '#42b53c', pending: '#e2dd64', reserved: '#2196f3', cancelled: '#e33333' };

const yieldColumns = [
  {
    title: <CustomTitle title="Date" />,
    dataIndex: 'payoutDate',
    render: DateCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="TheXchange Turnover" />,
    dataIndex: 'xchangeRevenue',
    render: YieldCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Your Turncoin" />,
    dataIndex: 'turncoinAmount',
    render: TurncoinCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Yield / Turncoin" />,
    dataIndex: ['yield', 'turncoinAmount' ],
    render: YieldCellPerTC,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Yield" />,
    dataIndex: 'yield',
    render: YieldCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
  {
    title: <CustomTitle title="Revenue File" />,
    dataIndex: 'revenue',
    render: RevenueCell,
    align: 'center',
    className: 'column-status',
    width: 250,
  },
];

function MobileTable({ yields }) {
  return (
    <div className="mb-order-table table-responsive">
      {yields.length > 0 && (<span className="" style={{display: 'flex', justifyContent: 'center', fontSize: '25px', fontWeight: '500'}}>Yields</span>)}
      <br/>
      {yields.map((y) => (
        <div className="order" key={y.id}>
          <div className="order-item">
            <span className="label">Date:</span>
            {DateCell(y.payoutDate)}
          </div>
          <div className="order-item">
            <span className="label">TheXchange Revenue:</span>
            <div
              style={{
                // backgroundColor: colors[order.status.toLowerCase()],
                height: 48,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>${numberFormatter(y.xchangeRevenue, 2)}</span>
            </div>
          </div>
          <div className="order-item">
            <span className="label">Turncoin:</span>
            <span className="value">{numberFormatter(y.turncoinAmount, 0)}</span>
          </div>
          <div className="order-item">
            <span className="label">Yield / Turncoin:</span>
            <span className="value">${numberFormatter(y.yield/y.turncoinAmount, 7)}</span>
          </div>
          <div className="order-item">
            <span className="label">Yield:</span>
            <span className="value">${`${numberFormatter(y.yield, 4)}`}</span>
          </div>
        </div>
      ))}
    </div>
  );
}


MobileTable.propTypes = {
  yields: PropTypes.array,
};

MobileTable.defaultProps = {
};

class YieldsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth < 768,
      yields:[],
    };
    this.scrollTimer = null;
    window.onresize = this.changeTableType;
  }

  componentDidMount() {
    ApiService({
      api: '/api/yields',
      method: 'GET',
    }).then((result) => {
      this.setState({yields: result.data.yields});
    });
  }

  changeTableType = () => {
    if(window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  };

  render() {
    const {yields} = this.state;
    const { status } = this.props;
    const { isMobile } = this.state;
    return (
      <div className="order-table-container">
        <div className="order-table">
          <div className="order-table-wrapper">
            {isMobile ? (
              <MobileTable yields={yields} />
            ) : (
              <>
                    {yields.length === 0 && (
                      <><div className="ant-descriptions-title">No Yield payouts found.</div></>
                    )}
                    {yields.length > 0 &&
                      (<><Divider /><div className="ant-descriptions-title">YIELDS</div><Table
                        columns={yieldColumns}
                        dataSource={yields}
                        rowKey="id"
                        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                        expandIconAsCell={false}
                        expandIconColumnIndex={isMobile ? 0 : yieldColumns.length - 1}
                        loading={status !== 'idle'} /></>
                      )}
                  </>
                  )}
                  </div>
        </div>
      </div>
    );
  }
}

YieldsTable.propTypes = {
  pagination: PropTypes.object,
  status: PropTypes.string,
  title: PropTypes.string,
  yields: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
};

YieldsTable.defaultProps = {
  pagination: {
    limit: 10,
    total: 0,
    page: 1,
  },
  yields: [],
  status: 'idle',
};

const mapStateToProps = (state) => ({
  pagination: state.order.pagination,
  status: state.order.status,
});


export default connect(mapStateToProps)(YieldsTable);
