import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import { authActions } from '../../core';
import '../../assets/styles/components/Forms/Login.less';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember: false,
      validated: false,
      errors: '',
    };
  }
  

  componentDidMount () {
    const { email } = this.props.auth.user;
    if(!email){
      this.props.history.push('/');
    }
    else {
      this.setState({email});
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const { password } = this.state;
    const { email } = this.props.auth.user;
    //call check on email
    this.props.login({ email, password }, this.onSuccess, this.onFail);
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  

  toggleRemember = () => {
    this.setState({ remember: !this.state.remember });
  };

  onSuccess = () => {
    this.props.history.push('/dashboard');
  };

  onFail = (response) => {
    this.setState({
      errors: response.message,
    });
  };

  render() {
    const { errors } = this.state;
    const { status, auth } = this.props;

    if(this.props.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="login_body">
        <div className="poppins-font">
          <div className="xchange_copywrite_message">© TheXchange™ 2017-2023. All rights reserved.</div>
          <div className="login_body_wrapper">
            <img src="https://s3.us-east-2.amazonaws.com/s3public.turncoin.io/turncoin/full_logo.png" loading="lazy" alt="" className="login_page_logo" />
            
            <div className="login_form_block w-form">
              <form id="wf-form-Turncoin-Login" name="wf-form-Turncoin-Login" data-name="Turncoin Login" onSubmit={this.handleSubmit}>
                <div className="turncoin_2col_input">
                 <p style={{color: 'wheat'}}>Login as: {auth?.user.email || this.state.email}</p>
                </div>
                <input type="password" onChange={this.onFieldChange} style={{color:'black'}} className="turncoin_registration_input_field w-input" 
                maxLength="256" name="password" data-name="Password" placeholder="Password" id="Password" required={true} />
              
                <div className="register_form_btn_wrapper">
                  <div className='turncoin_register-page_btn'>
                    <input
                      type="submit"
                      value={status === 'idle'? "Login": "Busy, please wait..."}
                      style={{backgroundColor: 'transparent', border: 0, width:'100%', height:'40px'}}
                      data-wait="Please wait..."
                      // className={`turncoin_submit_action w-button ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}
                      disabled={status === 'running' ? true : false}
                    />
                  </div>
                </div>

                {/*<div className="login_form_btn_wrapper">
                  <div className={`turncoin_login_btn ${status === 'running' && 'disable-btn'}`}>
                    <input type="submit" value="LOGIN" data-wait="Please wait..." 
                    style={{backgroundColor: '#D8B97E', border: 0}} className="turncoin_submit_action w-button" disabled={status === 'running' ? true : false} />
                  </div>
    </div>*/}
              </form>
            
              {/* <div>Oops! Something went wrong while submitting the form.</div> */}
              {!(errors === '' || errors === undefined || status === 'running') && (
                  <div className="error_message">
                    <p style={{color:'red'}}>{errors}</p>
                  </div>
                )
                }
              <p className="login_page_paragraph">
                  By accessing this website I agree to be bomiund by the
                  <a href="https://www.turncoin.com/terms" target="_blank" 
                  rel="noopener noreferrer" className="login_form_p_links"> Terms &amp; Conditions, Confidentiality Agreement</a> and
                  <a href="https://www.turncoin.com/terms" target="_blank" 
                  rel="noopener noreferrer" className="login_form_p_links"> Privacy Policy</a> of TheXchange and all of its SubXchanges &amp; associated websites.
                </p>
            </div>
            <Link to="/login/forgot" className="forgot_password_link" style={{color: 'wheat'}}>
              Forgot your Password?
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  status: PropTypes.string,
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object,
};

Login.defaultProps = {
  status: 'idle',
  email: '',
};

function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    auth,
    isAuthenticated,
    status: auth.status,
  };
}

const mapDispatchToProps = (dispatch) => ({
  login: (payload, resolve, reject) => dispatch(authActions.login(payload, resolve, reject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);