import { createAction } from 'redux-actions';
import { createPromiseAction } from '../utils';

/**
 * Action Types
 */
export const CREATE_ORDER_REQUEST = '@order/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = '@order/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = '@order/CREATE_ORDER_FAILURE';

export const CREATE_BUY_ORDER_REQUEST = '@order/CREATE_BUY_ORDER_REQUEST';
export const CREATE_BUY_ORDER_SUCCESS = '@order/CREATE_BUY_ORDER_SUCCESS';
export const CREATE_BUY_ORDER_FAILURE = '@order/CREATE_BUY_ORDER_FAILURE';

export const GET_ORDERS_REQUEST = '@order/GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = '@order/GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = '@order/GET_ORDERS_FAILURE';

export const GET_ANALYTICS_REQUEST = '@order/GET_ANALYTICS_REQUEST';
export const GET_ANALYTICS_SUCCESS = '@order/GET_ANALYTICS_SUCCESS';
export const GET_ANALYTICS_FAILURE = '@order/GET_ANALYTICS_FAILURE';

// actions to get cryptocurrency exchange rate
export const GET_EXCHANGE_RATE_REQUEST = 'GET_EXCHANGE_RATE_REQUEST';
export const GET_EXCHANGE_RATE_SUCCESS = 'GET_EXCHANGE_RATE_SUCCESS';
export const GET_EXCHANGE_RATE_FAILURE = 'GET_EXCHANGE_RATE_FAILURE';

/**
 * Action Creators
 */
export const orderActions = {
  createOrderRequest: createPromiseAction(CREATE_ORDER_REQUEST),
  createOrderSuccess: createAction(CREATE_ORDER_SUCCESS),
  createOrderFailure: createAction(CREATE_ORDER_FAILURE),

  createBuyOrderRequest: createAction(CREATE_BUY_ORDER_REQUEST),
  createBuyOrderSuccess: createAction(CREATE_BUY_ORDER_SUCCESS),
  createBuyOrderFailure: createAction(CREATE_BUY_ORDER_FAILURE),

  getOrdersRequest: createAction(GET_ORDERS_REQUEST),
  getOrdersSuccess: createAction(GET_ORDERS_SUCCESS),
  getOrdersFailure: createAction(GET_ORDERS_FAILURE),

  getAnalyticsRequest: createAction(GET_ANALYTICS_REQUEST),
  getAnalyticsSuccess: createAction(GET_ANALYTICS_SUCCESS),
  getAnalyticsFailure: createAction(GET_ANALYTICS_FAILURE),

  getExchangeRateRequest: createAction(GET_EXCHANGE_RATE_REQUEST),
  getExchangeRateSuccess: createAction(GET_EXCHANGE_RATE_SUCCESS),
  getExchangeRateFailure: createAction(GET_EXCHANGE_RATE_FAILURE),

};
