import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { numberFormatter } from '../../../utilities';
import SelectStatus from '../SelectStatus';

export const columnsForAllOrders = (method, onSelectOrder) => {
  
  let cols = [
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>EDIT</span>,
    dataIndex: 'EDIT',
    render: (text, record) => (
        <Button type="primary" onClick={() => onSelectOrder(record)}>EDIT {text}</Button>
    ),
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Date</span>,
    dataIndex: 'orderTimestamp',
    sorter: (a, b) => moment(a.orderTimestamp).diff(b.orderTimestamp),
    render: (text) => <span style={{ color: 'black' }}>{moment(text).format('MMM DD, YYYY')}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Order #</span>,
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>First</span>,
    dataIndex: 'firstName',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Last</span>,
    dataIndex: 'lastName',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Entity</span>,
    dataIndex: 'entityName',
    sorter: (a, b) => a.entityName.localeCompare(b.entityName),
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Dollars</span>,
    dataIndex: 'total',
    render: (text) => <span style={{ color: 'black' }}>{numberFormatter(text, 2)}</span>,
    sorter: (a, b) => a.total - b.total,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Price/TC</span>,
    dataIndex: 'itemCost',
    render: (text) => <span style={{ color: 'black' }}>{numberFormatter(text, 3)}</span>,
    sorter: (a, b) => a.itemCost - b.itemCost,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}># TurnCoin</span>,
    dataIndex: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    render: (text) => <span style={{ color: 'black' }}>{numberFormatter(text, 0)}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
];
if(method === 'Earned'){
  cols.push({
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Vestment Date</span>,
    dataIndex: 'vestmentDate',
    sorter: (a, b) => moment(a.vestmentDate).diff(b.vestmentDate),
    render: (text) => <span style={{ color: 'black' }}>{text !== null ? moment(text).format('MMM DD, YYYY') : ''}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },);
}
return cols;
};

export const columnsForUserOrders = (orders, onSelectOrder) => [
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Date</span>,
    dataIndex: 'orderTimestamp',
    render: (text) => <span style={{ color: 'black' }}>{moment(text).format('MMM DD, YYYY')}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Order #</span>,
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
    sortDirections: ['ascend', 'descend'],
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}># TurnCoin</span>,
    dataIndex: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    sortDirections: ['ascend', 'descend'],
    render: (text) => <span style={{ color: 'black' }}>{numberFormatter(text, 0)}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Dollar Amount</span>,
    dataIndex: 'total',
    render: (text) => <span style={{ color: 'black' }}>{numberFormatter(text, 2)}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Status</span>,
    dataIndex: 'status',
    sorter: (a, b) => a.status.length - b.status.length,
    render: (text) => <SelectStatus text={text} />,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>EDIT</span>,
    dataIndex: 'EDIT',
    render: (text, record) =>
      // if(record) onSelectOrder(record);
      // console.log(onSelectOrder)
      (
        <Button onClick={() => onSelectOrder(record)}>Edit {text}</Button>
      ),
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
  {
    title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Method</span>,
    dataIndex: 'method',
    sorter: (a, b) => a.method.localeCompare(b.method),
    render: (text) => <span style={{ color: 'black' }}>{text}</span>,
    align: 'center',
    className: 'table-value-content',
    width: 100,
  },
];
