import { combineReducers } from 'redux';

import {
  auth,
  order,
  admin,
} from '../modules';

const rootReducer = combineReducers({
  auth,
  order,
  admin,
});

export default rootReducer;
