import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';
import { numberFormatter } from '../../utilities';

import { columnsForAllOrders } from './columns';

const renderTradableTitle = (isLocked, status) => function renderTitle(orders) {
  const totalDollars = orders.filter((order) => Boolean(order.isLocked) === isLocked).reduce((sum, order) => sum + order.total, 0);
  const totalTC = orders.filter((order) => Boolean(order.isLocked) === isLocked).reduce((sum, order) => sum + order.amount, 0);
  const colorMapping = {
    RESERVED: '#e63523',
    ISSUED: '#5aad5b',
    PENDING: '#2c70ba',
  };
  const statusMapping = {
    RESERVED: 'Reserved',
    ISSUED: 'Issued',
    PENDING: 'Pending',
  };

  return (
    <div style={{ fontSize: 18, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', color: colorMapping[status] }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!isLocked
          ? <span style={{ fontWeight: 'bold' }}>Tradable TurnCoins</span>
          : <span style={{ fontWeight: 'bold' }}>Non-Tradable TurnCoins</span>}

        {status
          && <span style={{ fontWeight: 'bold' }}>{ statusMapping[status] }</span>}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div>
          <span style={{ minWidth: 110, display: 'inline-block' }}>Total Dollars:</span>
          <span style={{ minWidth: 120, display: 'inline-block', fontWeight: 'bold', textAlign: 'right' }}>{`${numberFormatter(totalDollars, 2)}`}</span>
        </div>

        <div>
          <span style={{ minWidth: 110, display: 'inline-block' }}>Total TurnCoin:</span>
          <span style={{ minWidth: 120, display: 'inline-block', fontWeight: 'bold', textAlign: 'right' }}>{numberFormatter(totalTC, 0)}</span>
        </div>

      </div>

    </div>
  );
};

function TableOrders({ tradableOrders, nonTradableOrders, method, onSelectOrder }) {
  return (
    <div>
      <Table
        title={renderTradableTitle(false, 'PENDING')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={tradableOrders.ordersList.filter((data) => data.status === 'PENDING' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={tradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
        style={{ marginBottom: 20 }}
      />
      <Table
        title={renderTradableTitle(false, 'ISSUED')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={tradableOrders.ordersList.filter((data) => data.status === 'ISSUED' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={tradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
        style={{ marginBottom: 20 }}
      />
      <Table
        title={renderTradableTitle(false, 'RESERVED')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={tradableOrders.ordersList.filter((data) => data.status === 'RESERVED' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={tradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
        style={{ marginBottom: 20 }}
      />

      {/*
      Non-Tradeable Turncoin Tables
    */}
      {method === 'Investment'
    && (
    <div>

      <Table
        title={renderTradableTitle(true, 'PENDING')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={nonTradableOrders.ordersList.filter((data) => data.status === 'PENDING' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={nonTradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
      />
      <Table
        title={renderTradableTitle(true, 'ISSUED')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={nonTradableOrders.ordersList.filter((data) => data.status === 'ISSUED' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={nonTradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
      />
      <Table
        title={renderTradableTitle(true, 'RESERVED')}
        columns={columnsForAllOrders(method, onSelectOrder)}
        dataSource={nonTradableOrders.ordersList.filter((data) => data.status === 'RESERVED' && data.method === method)}
        bordered
        rowKey="id"
        pagination={false}
        scroll={{ x: 'max-content' }}
        loading={nonTradableOrders.status !== 'idle'}
        rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
      />
    </div>
    )}
    </div>
  );
}

TableOrders.propTypes = {
  tradableOrders: PropTypes.object,
  nonTradableOrders: PropTypes.object,
  //handleEditItem: PropTypes.func.isRequired,
  //handleDeleteItem: PropTypes.func.isRequired,
  method: PropTypes.string,
};

TableOrders.defaultProps = {
  user: {},
};

export default TableOrders;
