import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import DescriptionCard from '../../components/Main/CoinDescription/DescriptionCard';
import idea from '../../assets/img/idea.png';
import dual from '../../assets/img/dual_nature.png';
import revolution from '../../assets/img/revolution.png';
import coin_logo from '../../assets/img/xicon.png';
import timeLine from '../../assets/img/timeline_20201126.png';
import TxtFormat from '../../components/TxtFormat/TxtFormat';

const cardData = [
  {
    cardClassName: 'idea_card',
    imgSrc: idea,
    title: 'THE MODEL',
    description: 'A people-driven platform to help individuals achieve their dreams, while giving their fans a way to support them and share in their success.',
  },
  {
    cardClassName: 'dual_card',
    imgSrc: dual,
    title: 'DUAL NATURE',
    description: 'TurnCoin™ is a Mega-Security Token that should deliver a perpetual increasing monthly yield and therefore continue to appreciate in value.',
  },
  {
    cardClassName: 'revolution_card',
    imgSrc: revolution,
    title: 'THE REVOLUTION',
    description: "Empower people worldwide to pursue their dreams and passions by listing themselves on a global people's exchange.",
  },
];

class CoinDescription extends PureComponent {
  render () {
    return (
      <div className="coin_description_block">
        <div className="card_area">
          <DescriptionCard
            cardClassName={cardData[0].cardClassName}
            cardLogo={cardData[0].imgSrc}
            title={cardData[0].title}
            description={cardData[0].description}
          />
          <DescriptionCard
            cardClassName={cardData[1].cardClassName}
            cardLogo={cardData[1].imgSrc}
            title={cardData[1].title}
            description={cardData[1].description}
          />
          <DescriptionCard
            cardClassName={cardData[2].cardClassName}
            cardLogo={cardData[2].imgSrc}
            title={cardData[2].title}
            description={cardData[2].description}
          />
        </div>
        <div className="coin_description">
          <div className="container">
            <div className="left_side_desc">
              <img src={coin_logo} alt="XCHANGE COIN" className="card_description_logo" />
            </div>
            <div className="right_side_desc">
              <p className="card_description-under-title">Backing People by Leveraging Blockchain Technology. </p>
              <p className="title">THE PEOPLE DRIVEN PLATFORM</p>
              <p className="description">
                <span className="medium">TheXchange™</span> is a people-driven platform that helps individuals to achieve their dreams, 
                while giving their fans a vehicle to support them and share in their success.
                <br /><br />
                <span className="medium">TheXchange™</span> will provide a powerful and dynamic platform for existing and upcoming stars 
                to pursue and realize their dreams by providing them with a global financial platform to fund their journey.
              </p>
              <Link to="/vision"><Button>{TxtFormat('main.read.more.btn.title')}</Button></Link>
            </div>
          </div>
          <div className="coin_description_block-timeline">
            <img src={timeLine} alt="time line" className="timeline-img" />
          </div>
        </div>
      </div>
    );
  }
}

export default CoinDescription;
