import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function PrivateRoute (props) {
  const { isAdmin, isAuthenticated, ...rest } = props;
  if(isAuthenticated && isAdmin === true) {
    return (
      <Route {...rest} />
    );
  }

  if(isAuthenticated && !isAdmin && props.path === '/backend') {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  }

  if(isAuthenticated) {
    return (
      <Route {...rest} />
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  );
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,

};

PrivateRoute.defaultProps = {
  isAdmin: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.user.isAdmin,
});

export default connect(mapStateToProps)(PrivateRoute);
