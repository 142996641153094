import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { ApiService } from '../../services/restService';

class Forgot extends React.Component {
  state = {
    email: '',
    password: '',
    password2: '',
    error: '',
    token: ',',
    status: 'idle',
    message: '',
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getResetToken = () => {
    const { email } = this.state;
    this.setState({ status: 'running' });
    ApiService({
      api: '/api/auth/forgot-password',
      method: 'POST',
      params: { email },
    })
      .then((data) => {
        this.setState({message: data.message});
      });
  };

  handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    this.getResetToken();
  };

  renderForgot = ()  => {
    const { error, status, message } = this.state;
    return (
      <div className="forgot_body">
        <div className="poppins-font">
          <div className="xchange_copywrite_message">© TheXchange™ 2017-2021. All rights reserved.</div>
          <div className="register_body_wrapper">
            <img src="/images/thexchange-logo.svg" loading="lazy" alt="" className="register_page_logo" />
            <h2 className="login_form_title" style={{color: 'wheat'}}>FORGOT PASSWORD</h2>
            <div className="login_form_block w-form">
              <form id="wf-form-Forgot-Password" name="wf-form-Forgot-Password" data-name="Forgot Password" onSubmit={this.handleSubmit}>
                <input type="email" onChange={this.onFieldChange} 
                className="turncoin_registration_input_field w-input"
                style={{width:'100%', padding: '10px', color:'black', borderRadius: '5px'}} 
                maxLength="256" name="email" data-name="Email" 
                placeholder="Enter Email" id="Email" required={true} />
                   
                  <div className={`register_form_btn_wrapper" ${status === 'running' && 'disable-btn'}`} style={{paddingTop:'10px'}}>
                    <div className='turncoin_register-page_btn'>
                    <input
                      type="submit"
                      value="Continue"
                      style={{backgroundColor: 'transparent', border: 0, width:'100%', height:'40px'}}
                      data-wait="Please wait..."
                      // className={`turncoin_submit_action w-button ${(status === 'running' || !this.isValid()) ? 'disable-btn' : ''}`}
                      disabled={status === 'running' ? true : false}
                      />
                    </div>
                  </div>
                  
                  {!(message === '' || message === undefined) && (
                  <div className="message" style={{color: 'white'}}>
                    {message}
                  </div>
                )
              }
              </form>
              {!(error === '' || error === undefined) && (
                  <div className="error_message">
                    {error}
                  </div>
                )
              }
            </div>
            <Link to="/login" className="turncoin_back_button w-inline-block">
              <div className="text-block-6" style={{color:'wheat', paddingTop:'20px'}}>Back</div>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderForgot() }
      </div>
    );
  }
}

export default withRouter(Forgot);
