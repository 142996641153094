import { put, call, all, takeLatest } from 'redux-saga/effects';
import { message, Modal } from 'antd';
import { authActions } from '../auth/actions';
import {
  orderActions,
  CREATE_ORDER_REQUEST,
  CREATE_BUY_ORDER_REQUEST,
  GET_ORDERS_REQUEST,
  GET_ANALYTICS_REQUEST,
  GET_EXCHANGE_RATE_REQUEST,
} from './actions';

import { ApiService } from '../../../services';

export function* createOrder({ payload, resolve, reject }) {
  const { amount, paymentMethod } = payload;
  try {
    const response = yield call(
      ApiService,
      {
        api: '/api/order',
        method: 'POST',
        params: {
          amount,
          paymentMethod,
        },
      },
    );
    // @TODO: Open next lines after login api is completed
    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(orderActions.createOrderSuccess({ order: response.data }));
    resolve(response);
  } catch(e) {
    message.error(e.message || 'Sorry an error occured while processing your request.');
    yield put(orderActions.createOrderFailure({ message: e.message }));
    reject(e);
  }
}

export function* createBuyOrder() {
  try {
    const response = yield call(ApiService, { // eslint-disable-line
      api: '/api/request-buy',
      method: 'POST',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(orderActions.createBuyOrderSuccess());
    Modal.success({
      title: 'Thank you for your interest in purchasing TurnCoin™, a representative from TheXchange™ will be in contact with you shortly.',
      content: null,
      okButtonProps: {
        size: 'large',
        style: { minWidth: 100 },
      },
      onOk() {},
    });
  } catch(e) {
    message.error(e.message || 'Sorry an error occured while processing your request.');
    yield put(orderActions.createBuyOrderFailure());
  }
}

export function* getOrders({ payload }) {
  try {
    const { page = 0, limit = 10 } = payload;
    const response = yield call(ApiService, {
      api: `/api/order?page=${page}&limit=${limit}`,
      method: 'GET',
    });

    if(!response.success) {
      yield put(authActions.loginFailure({ message: response.message }));
      throw new Error(response.message);
    }
    yield put(orderActions.getOrdersSuccess(response.data));
  } catch(e) {
    yield put(orderActions.getOrdersFailure());
  }
}

export function* getAnalytics() {
  try {
    const response = yield call(ApiService, {
      api: '/api/order/analytics',
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(orderActions.getAnalyticsSuccess(response.data));
  } catch(e) {
    yield put(orderActions.getAnalyticsFailure());
  }
}

export function* getExchangeRate() {
  try {
    const response = yield call(ApiService, {
      api: '/api/user/exchange-rate',
      method: 'GET',
    });

    if(!response.success) {
      throw new Error(response.message);
    }
    yield put(orderActions.getExchangeRateSuccess(response.data));
  } catch(e) {
    yield put(orderActions.getExchangeRateFailure());
  }
}

export default function* () {
  yield all([
    takeLatest(CREATE_ORDER_REQUEST, createOrder),
    takeLatest(CREATE_BUY_ORDER_REQUEST, createBuyOrder),
    takeLatest(GET_ORDERS_REQUEST, getOrders),
    takeLatest(GET_ANALYTICS_REQUEST, getAnalytics),
    takeLatest(GET_EXCHANGE_RATE_REQUEST, getExchangeRate),
  ]);
}
