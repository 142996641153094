import React from 'react';

export const colors = { issued: '#42b53c', pending: '#e2dd64', reserved: '#2196f3', cancelled: '#e33333' };

export default class SelectStatus extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          backgroundColor: colors[this.props.text.toLowerCase()],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 5,
          paddingRight: 10,
          height: 50,
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {this.props.text}
      </div>
    );
  }
}
