/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Button, Modal, Input, Form, message } from 'antd';

import PropTypes from 'prop-types';
import { authActions } from '../../core';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

class EditUserModal extends PureComponent {
  editRef = React.createRef();

  render() {
    // eslint-disable-next-line react/prop-types
    const { visible, onCancel, onSubmit, defaultValues } = this.props;

    return (
      <Modal
        visible={visible}
        title="Edit Personal Info"
        okText="Update"
        onCancel={onCancel}
        onOk={onSubmit}
        centered
      >
        <Form
          {...layout}
          ref={this.editRef}
          initialValues={{
            firstName: defaultValues.firstName,
            middleName: defaultValues.middleName,
            lastName: defaultValues.lastName,
            country: defaultValues.country,
            address: defaultValues.address,
            city: defaultValues.city,
            postalCode: defaultValues.postalCode,
            state: defaultValues.state,
            phone: defaultValues.phone,
            phonePrefix: defaultValues.phonePrefix,
          }}
        >

          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="middleName"
            label="Middle Name"
            rules={[{ required: false, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Physical address"
            rules={[{ required: true, message: 'Please input your address!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: 'Please input your city!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="country"
            label="Country of residence"
            rules={[{ required: true, message: 'Please input your country of residence!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="postalCode"
            label="Postal Code"
            rules={[{ required: true, message: 'Please input your postal code!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="state"
            label="State"
            rules={[{ required: true, message: 'Please input your State!' }]}
          >
            <Input />
          </Form.Item>

        <Form.Item label="Phone">
        <Input.Group compact>
          <Form.Item
            name="phonePrefix"
            noStyle
            rules={[{ required: true, message: 'Country code is required' }]}
          >
             <Input  style={{ width: '30%' }} type="tel" />
          </Form.Item>
          <Form.Item
            name="phone"
            noStyle
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input type="tel" style={{ width: '70%' }} pattern="[+]{2}-[0-9]{2}-[0-9]{3}" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

         

        </Form>
      </Modal>
    );
  }
}


EditUserModal.propTypes = {
  user: PropTypes.object,
  defaultValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    phone: PropTypes.string,
    phonePrefix: PropTypes.string,
  }).isRequired,
};

EditUserModal.defaultProps = {
  user: {},
};

class PersonalInfoTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.userModalRef = React.createRef();
  }

  componentDidMount() {

  }

  editUserModalRef = (ref) => {
    this.userModalRef = ref;
  };

  handleUpdateUserInfo = () => {
    const { editRef } = this.userModalRef.current;
    const { updatePersonalInfo } = this.props;
    editRef.current.validateFields().then((values) => {
      this.setState({ visible: false });
      updatePersonalInfo({ ...values });
      //message.success('Updated successfully!');
    }).catch((err) => {
      //err.errorFields.map((field) => message.error(field.errors[0]));
      console.log(err);
      message.error('Update failed!');
    });
  };

  handleCancel = () => {
    // const { form } = this.userModalRef.current
    this.setState({ visible: false });
  };

  render() {
    const { user } = this.props;
    const { visible } = this.state;
    return (
      <div className="user-details-block-wrapper">
        <Descriptions
          bordered
          title="Personal Info"
          column={1}
          className="user-details-content"
        >
          <Descriptions.Item label="First Name">{user.firstName}</Descriptions.Item>
          <Descriptions.Item label="Middle Name">{user.middleName}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{user.lastName}</Descriptions.Item>
          <Descriptions.Item label="Address">{user.address}</Descriptions.Item>
          <Descriptions.Item label="City">{user.city}</Descriptions.Item>
          <Descriptions.Item label="Country">{user.country}</Descriptions.Item>
          <Descriptions.Item label="Postal Code">{user.postalCode}</Descriptions.Item>
          <Descriptions.Item label="State">{user.state}</Descriptions.Item>
          <Descriptions.Item label="Phone">{user.phonePrefix+user.phone}</Descriptions.Item>
          {user.entityName !== '' && (<Descriptions.Item label="Entity">{user.entityName}</Descriptions.Item>)}
          <Descriptions.Item label="KYC Status">{user.kycStatus}</Descriptions.Item>
          {user.kycDate !== null && (
            <>
            <Descriptions.Item label="KYC Date">{moment(user.kycDate).format('MMM DD, YYYY')}</Descriptions.Item>
            <Descriptions.Item label="KYC Expiry Date">{moment(user.kycExpiry).format('MMM DD, YYYY')}</Descriptions.Item>
            </>
          )}

          {user.accountType == 'Entity' && (
              <>
                <Descriptions.Item label="KYB Status">{user.kybStatus}</Descriptions.Item>
                <Descriptions.Item label="KYB Date">{moment(user.kybDate).format('MMM DD, YYYY')}</Descriptions.Item>
                <Descriptions.Item label="KYB Expiry Date">{moment(user.kybExpiry).format('MMM DD, YYYY')}</Descriptions.Item>
              </>
          )}
          <Descriptions.Item label="Accreditation Status">{user.accreditationStatus}</Descriptions.Item>
          {user.accreditationDate !== null && (
            <>
            <Descriptions.Item label="Accreditation Date">{moment(user.accreditationDate).format('MMM DD, YYYY')}</Descriptions.Item>
            <Descriptions.Item label="Accreditation Expiry Date">{moment(user.accreditationExpiry).format('MMM DD, YYYY')}</Descriptions.Item>
            </>
          )}
        </Descriptions>

        <EditUserModal
          ref={this.userModalRef}
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleUpdateUserInfo}
          defaultValues={{ ...user }}
        />

        <Button
          className="turncoin-btn"
          onClick={() => this.setState({ visible: true })}
        >
          Edit Personal Info
        </Button>
      </div>
    );
  }
}

PersonalInfoTable.propTypes = {
  updatePersonalInfo: PropTypes.func.isRequired,
  user: PropTypes.object,
  defaultValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    phone: PropTypes.string,
    phonePrefix: PropTypes.string,
  }),
};

PersonalInfoTable.defaultProps = {
  user: {},
  defaultValues: {},
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {
  updatePersonalInfo: authActions.updatePersonalInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoTable);
