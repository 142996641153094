import React from 'react';
import { FormattedMessage } from 'react-intl';

function TxtFormat(id) {
  return (
    <FormattedMessage
      id={id}
    />
  );
}
export default TxtFormat;
