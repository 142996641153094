import React from 'react';

function MobileBackendTableUsers({ users, handleUser }) {
  return (
    <div className="mb-order-table table-responsive">
      {users.map((user) => (
        <div className="order" key={user.id} onClick={() => handleUser(user)}>
          <div className="order-item">
            <span className="label">First Name:</span>
            <span className="value">{user.firstName}</span>
          </div>
          <div className="order-item">
            <span className="label">Last Name:</span>
            {user.lastName}
          </div>
          <div className="order-item">
            <span className="label">Email:</span>
            <span className="value">{user.email}</span>
          </div>
          <div className="order-item">
            <span className="label">Entity:</span>
            <span className="value">{user.entityName}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MobileBackendTableUsers;
