import { createAction } from 'redux-actions';
import { createPromiseAction } from '../utils';

/**
 * Action Types
 */
export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';

export const LOGIN_REQUEST = '@login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@login/LOGIN_FAILURE';

export const SIGNUP_REQUEST = '@signup/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = '@signup/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = '@signup/SIGNUP_FAILURE';

export const LOGOUT_REQUEST = '@logout/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@logout/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@logout/LOGOUT_FAILURE';

export const GET_USER_INFO_REQUEST = '@GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = '@GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = '@GET_USER_INFO_FAILURE';

export const UPDATE_PERSONAL_INFO_REQUEST = '@UPDATE_PERSONAL_INFO_REQUEST';
export const UPDATE_PERSONAL_INFO_SUCCESS = '@UPDATE_PERSONAL_INFO_SUCCESS';
export const UPDATE_PERSONAL_INFO_FAILURE = '@UPDATE_PERSONAL_INFO_FAILURE';
/**
 * Action Creators
 */
export const authActions = {
  createPassword: createPromiseAction(CREATE_PASSWORD_REQUEST),
  createPasswordSuccess: createAction(CREATE_PASSWORD_SUCCESS),
  createPasswordFailure: createAction(CREATE_PASSWORD_FAILURE),

  login: createPromiseAction(LOGIN_REQUEST),
  loginSuccess: createAction(LOGIN_SUCCESS),
  loginFailure: createAction(LOGIN_FAILURE),

  signUp: createPromiseAction(SIGNUP_REQUEST),
  signUpSuccess: createAction(SIGNUP_SUCCESS),
  signUpFailure: createAction(SIGNUP_FAILURE),
  logoutRequest: createPromiseAction(LOGOUT_REQUEST),
  logoutSuccess: createAction(LOGOUT_SUCCESS),
  logoutFailure: createAction(LOGOUT_FAILURE),

  getUserInfoRequest: createAction(GET_USER_INFO_REQUEST),
  getUserInfoSuccess: createAction(GET_USER_INFO_SUCCESS),
  getUserInfoFailure: createAction(GET_USER_INFO_FAILURE),

  updatePersonalInfoRequest: createAction(UPDATE_PERSONAL_INFO_REQUEST),
  updatePersonalInfoSuccess: createAction(UPDATE_PERSONAL_INFO_SUCCESS),
  updatePersonalInfoFailure: createAction(UPDATE_PERSONAL_INFO_FAILURE),
};
