import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { ApiService } from '../../services';

class RegisterSuccess extends React.PureComponent {

  resendVerificationEmail = () => {
    const {email} = this.props.auth.user;

    const response = ApiService({
      api: '/api/auth/verification-email',
      method: 'POST',
      params: { email },
    });
    console.log(response);
  };
  
 
  render() {
    return (
      <div className="register_body register_success_body">
        <div className="poppins-font">
          <div className="xchange_copywrite_message">© TheXchange™ 2017-2023. All rights reserved.</div>
          <div className="register_body_wrapper">
            <img src="https://s3.us-east-2.amazonaws.com/s3public.turncoin.io/turncoin/full_logo.png" loading="lazy" alt="" className="register_page_logo" />
            <div className="coin_logo">

            </div>
            <h2 className="consultant_success_title">
              Thank You <span>FOR REGISTERING!</span>
            </h2>
            <h2 className="consultant_success_description check-email">
              Please check your mail
            </h2>
            <button style={{backgroundColor: '#54524c', border: '0', fontSize:'x-small', cursor: 'pointer'}} onClick={this.resendVerificationEmail}>
              Resend Verification Email
            </button>
            <div style={{paddingTop: '20px'}}className="login_form_btn_wrapper">
              <Link to="/">
                <div className="turncoin_register-page_btn">
                  <input className="turncoin_submit_action w-button" />
                  <div>DONE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisterSuccess.propTypes = {
  isAuthenticated: PropTypes.bool,
  auth: PropTypes.object,
};

function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    auth,
    isAuthenticated,
    status: auth.status,
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSuccess);
