import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BackendLoginHistory from '../../components/Backend/BackendLoginHistory';
import { adminActions } from '../../core';

function BackendUserLogins(props) {
  const { userLogins, status, getUserLoginsRequest } = props;
  return (
    <BackendLoginHistory
      data={userLogins}
      status={status}
      getUsersRequest={getUserLoginsRequest}
    />
  );
}

BackendUserLogins.defaultProps = {
  userLogins: {},
};

BackendUserLogins.propTypes = {
  userLogins: PropTypes.shape({
    users: PropTypes.array,
    userHistory: PropTypes.array,
  }),
  getUserLoginsRequest: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = ({ admin }) => ({
  userLogins: admin.userLogins,
  status: admin.status,
});

const mapDispatchToProps = {
  getUserLoginsRequest: adminActions.userLoginsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackendUserLogins);
