import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import moment from 'moment';

class BackendLoginHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: window.innerWidth < 768,
      firstName: '',
      lastName: '',
      searchText: '',
      searchedColumn: '',
      searchText2: '',
      searchedColumn2: '',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.changeTableType);
    const { pagination } = this.props.data;
    this.props.getUsersRequest(pagination);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeTableType);
  }

  changeTableType = () => {
    const { isMobile } = this.state;
    if(window.innerWidth < 768 && !isMobile) {
      this.setState({ isMobile: true });
    } else if(window.innerWidth >= 768 && isMobile) {
      this.setState({ isMobile: false });
    }
  };

  handleUser = (user) => {
    this.props.history.push(`/backend/users/${user.id}`);
  };

  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onPageChange = (page) => {
    const { limit } = this.props.data.pagination;
    const { firstName, lastName } = this.state;
    this.props.getUsersRequest({
      page,
      limit,
      firstName,
      lastName,
    });
  };

  handleSubmit = () => {
    this.onPageChange(0);
  };

  getColumnSearchProps = (dataIndex, table) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex, table)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, table)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters, table)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              if(table === 'activeSessionsTable') {
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }

              if(table === 'userHistoryTable') {
                this.setState({
                  searchText2: selectedKeys[0],
                  searchedColumn2: dataIndex,
                });
              }
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if(visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
  });

   handleSearch = (selectedKeys, confirm, dataIndex, table) => {
     confirm();
     if(table === 'activeSessionsTable') {
       this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
       });
     }

     if(table === 'userHistoryTable') {
       this.setState({
         searchText2: selectedKeys[0],
         searchedColumn2: dataIndex,
       });
     }
   };

  handleReset = (clearFilters, table) => {
    clearFilters();
    if(table === 'activeSessionsTable') {
      this.setState({ searchText: '' });
    }
    if(table === 'userHistoryTable') {
      this.setState({ searchText2: '' });
    }
  };

  render() {
    const { userHistory, users } = this.props.data;
    const columns = [
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>First Name</span>,
        dataIndex: 'firstName',
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        render: (text) => (this.state.searchedColumn === 'firstName' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('firstName', 'activeSessionsTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Last Name</span>,
        dataIndex: 'lastName',
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        render: (text) => (this.state.searchedColumn === 'lastName' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('lastName', 'activeSessionsTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Email</span>,
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (text) => (this.state.searchedColumn === 'email' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('email', 'activeSessionsTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Last Activity</span>,
        dataIndex: 'loginTime',
        // defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.loginTime).diff(b.loginTime),
        render: (text) => <span style={{ color: 'black' }}>{moment(text).format('DD-MM-YYYY HH:mm:ss')}</span>,
        className: 'table-user-login-content',
        width: '25%',
      },
    ];

    const columns2 = [
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>First Name</span>,
        dataIndex: 'firstName',
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        render: (text) => (this.state.searchedColumn2 === 'firstName' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText2]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('firstName', 'userHistoryTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Last Name</span>,
        dataIndex: 'lastName',
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        render: (text) => (this.state.searchedColumn2 === 'lastName' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText2]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('lastName', 'userHistoryTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Email</span>,
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (text) => (this.state.searchedColumn2 === 'email' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText2]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span style={{ color: 'black' }}>{text}</span>
        )),
        className: 'table-user-login-content',
        width: '25%',
        ...this.getColumnSearchProps('email', 'userHistoryTable'),
      },
      {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Logged In At</span>,
        dataIndex: 'lastLogin',
        // defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.lastLogin).diff(b.lastLogin),
        render: (text) => <span style={{ color: 'black' }}>{moment(text).format('DD-MM-YYYY HH:mm:ss')}</span>,
        className: 'table-user-login-content',
        width: '25%',
      },
      /* {
        title: <span style={{ color: '#07174A', fontWeight: 'bold' }}>Logged Out At</span>,
        dataIndex: 'lastLogout',
        render: text => <span style={{ color: 'black' }}>{text !== null ? moment(text).format('DD-MM-YYYY HH:mm:ss') : 'Expired'}</span>,
        className: 'table-user-login-content',
        width: '25%',
      }, */
    ];

    /* const paginationObject = pagination ? {
      defaultPageSize: pagination.limit,
      current: pagination.page,
      total: pagination.total,
      onChange: this.onPageChange,
    } : {}; */

    return (
      <>
        <div className="ant-descriptions-title">Active Sessions</div>
        <Table
          className="table-user-logins"
          columns={columns}
          dataSource={users}
          bordered
          rowKey="sid"
          pagination={false}
          loading={this.props.status === 'running'}
          rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
          onRow={(record) => ({
            onClick: () => this.handleUser(record),
          })}
        />
        <div className="ant-descriptions-title">History</div>
        <Table
          className="table-user-logins"
          columns={columns2}
          dataSource={userHistory}
          bordered
          rowKey="hid"
          // pagination={{ defaultPageSize: 60, showSizeChanger: true }}
          pagination={false}
          loading={this.props.status === 'running'}
          scroll={{ y: 560 }}
          rowClassName={(record, index) => (index % 2 === 1 ? 'odd' : 'even')}
          onRow={(record) => ({
            onClick: () => this.handleUser(record),
          })}
        />
      </>
    );
  }
}

BackendLoginHistory.propTypes = {
  data: PropTypes.shape({
    users: PropTypes.array,
    userHistory: PropTypes.array,
    pagination: PropTypes.object,
  }),
  status: PropTypes.string,
  getUsersRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  tradableOrders: PropTypes.object,
};

BackendLoginHistory.defaultProps = {
  status: 'idle',
  data: {
    users: [],
    pagination: {},
  },
  match: {},
  tradableOrders: {}
};

export default withRouter(BackendLoginHistory);
