import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Switch, Redirect } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import Icon, { UserOutlined, FilePdfOutlined } from '@ant-design/icons';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';

// import { numberFormatter } from '../../utilities';
import { orderActions } from '../../core';

// import BackendHeader from './BackendHeader';
import DashboardHeader from '../Dashboard/DashboardHeader';
import BackendUserPageDetails from '../../components/Backend/BackendUserPageDetails';
import BackendUsers from './BackendUsers';
import BackendUserLogins from './BackendUserLogins';
import BackendOrders from './BackendOrders';
import BackendYields from './BackendYields';

const { Sider } = Layout;

function OrdersSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Union_9" data-name="Union 9" d="M11-203v-16h2v16Zm-14,0v-16h2v16Zm11-5v-11h2v11Zm-5,0v-11H7v11Zm-3,0v-11H2v11Z" transform="translate(3 219)" />
    </svg>
  );
}

function UsersSVG() {
  return (
    <svg width="20" height="20" id="Users" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 555.084 555.084">
      <path d="M433.908,332.01c8.16,4.08,12.24,11.018,12.24,20.811v78.945v14.076
			c0,6.119-1.836,11.424-5.508,15.912s-8.365,6.732-14.076,6.732H126.072c-5.304,0-9.792-2.244-13.464-6.732
			s-5.508-9.793-5.508-15.912v-14.076v-78.945c0-9.793,4.08-16.73,12.24-20.811l84.456-39.168l33.048-15.3
			c-15.504-9.384-27.336-22.848-35.496-40.392c-7.752-15.504-11.628-32.028-11.628-49.572c0-5.304,0.408-10.404,1.224-15.3
			s1.836-9.588,3.06-14.076c5.712-20.808,16.014-37.944,30.906-51.408s32.13-20.196,51.714-20.196
			c19.991,0,37.536,6.936,52.632,20.808s25.297,31.416,30.6,52.632c2.041,10.2,3.063,19.38,3.063,27.54
			c0,16.32-3.266,32.028-9.791,47.124c-8.16,18.36-19.992,32.232-35.496,41.616l34.883,16.524L433.908,332.01L433.908,332.01z
			 M107.1,306.305c-8.976,4.08-16.014,10.305-21.114,18.666c-5.1,8.365-7.65,17.646-7.65,27.85v78.945H15.3
			c-4.488,0-8.16-1.732-11.016-5.199C1.428,423.096,0,418.711,0,413.409v-74.666c0-8.979,3.264-14.689,9.792-17.137l94.86-44.064
			c-11.424-6.936-20.604-16.83-27.54-29.682s-10.404-27.234-10.404-43.146c0-11.016,1.836-21.522,5.508-31.518
			c3.672-9.996,8.67-18.666,14.994-26.01c6.324-7.344,13.668-13.158,22.032-17.442s17.442-6.426,27.234-6.426
			c6.528,0,12.648,1.02,18.36,3.06s11.22,4.692,16.524,7.956c-6.936,17.136-10.404,34.884-10.404,53.244
			c0,14.28,2.04,28.05,6.12,41.31s9.792,25.194,17.136,35.802c-4.488,4.896-9.18,8.772-14.076,11.628L107.1,306.305z
			 M545.293,321.606c6.525,3.264,9.791,8.977,9.791,17.137v74.664c0,5.303-1.43,9.688-4.283,13.158
			c-2.857,3.467-6.529,5.199-11.02,5.199H474.91v-78.945c0-10.201-2.551-19.482-7.648-27.85
			c-5.102-8.361-11.936-14.586-20.502-18.666l-60.588-28.761c-5.711-3.264-11.223-7.956-16.523-14.076
			c6.938-10.608,12.342-22.338,16.217-35.19c3.877-12.852,5.814-26.418,5.814-40.698c0-9.384-0.816-18.36-2.447-26.928
			c-1.633-8.568-4.08-16.932-7.344-25.092c5.303-3.672,11.016-6.63,17.135-8.874c6.121-2.244,12.445-3.366,18.975-3.366
			c9.793,0,18.973,2.142,27.537,6.426c8.568,4.284,16.018,10.098,22.342,17.442c6.32,7.344,11.318,16.014,14.99,26.01
			c3.676,9.996,5.512,20.502,5.512,31.518c0,15.504-3.471,29.682-10.404,42.534c-6.939,12.852-15.709,22.746-26.316,29.682
			L545.293,321.606L545.293,321.606z"
      />
    </svg>
  );
}

class Backend extends Component {
  componentDidMount () {
    this.props.getAnalytics();
  }

  render () {
    const { user, orderAnalytics } = this.props;
    const completedStats = orderAnalytics.find(({ status }) => (status === 'ISSUED'));
    const holdings = completedStats ? completedStats.totalAmount : 0;

    return (
      <div className="backend-container" style={{backgroundColor: 'white'}}>
        {/* <BackendHeader user={user} holdings={numberFormatter(holdings, 0)} /> */}
        <DashboardHeader user={user} holdings={holdings} />
        <div className="backend">
          <div className="backend-menu">
            <Layout>
              <Sider
                breakpoint="lg"
                collapsedWidth="48"
                width={240}
              >
                <Menu
                  // defaultSelectedKeys={['1']}
                  mode="inline"
                  theme="dark"
                  inlineIndent={0}
                >
                  <Menu.Item key="1">
                    <NavLink
                      className="menu-item"
                      activeClassName="menu-item-selected"
                      to="/backend/users"
                    >
                      <Icon component={UsersSVG} />
                      <span>Users</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <NavLink
                      className="menu-item"
                      activeClassName="menu-item-selected"
                      exact
                      to="/backend/user-sessions"
                    >
                      <UserOutlined style={{ fontSize: 18 }} />
                      <span>User Sessions</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <NavLink
                      className="menu-item"
                      activeClassName="menu-item-selected"
                      to="/backend/orders"
                    >
                      <Icon component={OrdersSVG} />
                      <span>Register</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <NavLink
                      className="menu-item"
                      activeClassName="menu-item-selected"
                      to="/backend/documents"
                    >
                      <FilePdfOutlined style={{ fontSize: 18 }} />
                      <span>Virtual Data Room</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <NavLink
                      className="menu-item"
                      activeClassName="menu-item-selected"
                      to="/backend/yields"
                    >
                      <FilePdfOutlined style={{ fontSize: 18 }} />
                      <span>Yields</span>
                    </NavLink>
                  </Menu.Item>
                </Menu>
              </Sider>
            </Layout>
          </div>
          <div className="backend-content">
            <Switch>
              <Redirect exact from="/backend" to="/backend/orders" />
              <PrivateRoute path="/backend/orders" component={BackendOrders} />
              <PrivateRoute exact path="/backend/users" component={BackendUsers} />
              <PrivateRoute exact path="/backend/user-sessions" component={BackendUserLogins} />
              <PrivateRoute exact path="/backend/users/:userId" component={BackendUserPageDetails} />
              <PrivateRoute exact path="/backend/yields" component={BackendYields} />             
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Backend.propTypes = {
  getAnalytics: PropTypes.func.isRequired,
  user: PropTypes.object,
  orderAnalytics: PropTypes.array,
};

Backend.defaultProps = {
  user: null,
  orderAnalytics: [],
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  orderAnalytics: state.order.analytics,
});

const mapDispatchToProps = (dispatch) => ({
  getAnalytics: (payload) => dispatch(orderActions.getAnalyticsRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Backend);
